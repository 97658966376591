import { defineStore } from 'pinia';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import { useModelStore } from '../datasets-models';
import { vtpWriter } from '../../io/vtk/async';
import { StateFile } from '@/src/io/state-file/schema';

interface Annotation {
    id: number;
    name: string;
    modelId: string;
}

type Mode = 'paint' | 'remove';

interface AnnotationState {
    annotations: Annotation[];
    visibleAnnotation: Record<string, number | null>; // modelId -> visible annotation ID or null
    nextId: number; // Global array ID across all models
    updated: boolean;
    active: boolean;
    brushSize: number; // Brush size (1 to 10)
    labelValue: number; // Label value (1 to 40)
    mode: Mode; // 'paint' or 'remove'
}

export const useSurface3DAnnotationStore = defineStore('annotations', {
    state: (): AnnotationState => ({
        annotations: [],
        visibleAnnotation: {},
        nextId: 1, // Start ID from 1 for scalar array IDs
        updated: false,
        active: false,
        brushSize: 1, // Default brush size
        labelValue: 1, // Default label value
        mode: 'paint', // Default mode is painting
    }),
    actions: {
        addAnnotation(name: string, modelId: string) {
            const id = this.nextId++;
            const annotation: Annotation = { id, name, modelId };
            this.annotations.push(annotation);

            const numCells = this.getNumCellsFromModel(modelId);

            // Initialize scalar values directly within the polydata
            const scalars = new Float32Array(numCells);
            for (let i = 0; i < numCells; i++) {
                scalars[i] = 0; // Initialize each cell scalar value to 0.0
            }

            // Create scalar array with naming convention "Scalars-[id]-[name]"
            const scalarArrayName = `Scalars-${id}-${name}`;
            const scalarArray = vtkDataArray.newInstance({
                name: scalarArrayName,
                values: scalars,
            });

            // Add scalars to polydata of the model
            const modelStore = useModelStore();
            const polydata = modelStore.dataIndex[modelId].getCellData();
            polydata.addArray(scalarArray);
            polydata.modified();

            return annotation;
        },
        getAnnotations() {
            return this.annotations;
        },
        getBrushValue() {
            if(this.mode === 'paint') {
                return [this.labelValue];
            } else {
                return [0];
            }
        },
        removeAllAnnotations() {
            const ids = this.annotations.map(annotation => annotation.id);
            ids.forEach(id => {this.removeAnnotation(id)});
        },
        getAnnotationsByModelId(modelId: string) {
            return this.annotations.filter(annotation => annotation.modelId === modelId);
        },
        getAnnotation(annotationId: number) {
            return this.annotations.find(annotation => annotation.id === annotationId);
        },
        isVisible(annotationId: number) {
            const modelId = this.getAnnotation(annotationId)?.modelId;
            if (modelId) {
                return this.visibleAnnotation[modelId] === annotationId;
            } else {
                return false;
            }
        },
        removeAnnotation(id: number) {
            const annotation = this.annotations.find(annotation => annotation.id === id);
            if (annotation) {
                this.removeArray(annotation.modelId, id);

                this.annotations = this.annotations.filter(annotation => annotation.id !== id);

                if (this.visibleAnnotation[annotation.modelId] === id) {
                    this.setVisibleAnnotation(annotation.modelId, null);
                }

                this.setVisibleAnnotation(annotation.modelId, null);
            }
            this.update();
        },
        updateAnnotationName(id: number, newName: string) {
            const annotation = this.annotations.find(annotation => annotation.id === id);
            if (annotation) {
                const modelStore = useModelStore();
                const polydata = modelStore.dataIndex[annotation.modelId].getCellData();

                // Find the current scalar array by its old name
                const oldArrayName = `Scalars-${id}-${annotation.name}`;
                const scalarArray = polydata.getArrayByName(oldArrayName);
                
                if (scalarArray) {
                    // Update the name of the array to reflect the new name
                    const newArrayName = `Scalars-${id}-${newName}`;
                    scalarArray.setName(newArrayName);
                    polydata.modified();
                }

                annotation.name = newName;
                this.update();
            }
        },
        getVisibleAnnotationIdByModelId(modelId: string) {
            return this.visibleAnnotation[modelId] || null;
        },
        removeVisibleAnnotationID(modelId: string) {
            this.setVisibleAnnotation(modelId, null);
            this.update();
        },
        toggleVisibility(annotationId: number) {
            const annotation = this.annotations.find(annotation => annotation.id === annotationId);

            if (annotation) {
                if (this.visibleAnnotation[annotation.modelId]) {
                    this.setVisibleAnnotation(annotation.modelId, null);
                } else {
                    this.setVisibleAnnotation(annotation.modelId, annotation.id);
                }
            }
        },
        getNumCellsFromModel(modelId: string): number {
            const modelStore = useModelStore();
            const model = modelStore.dataIndex[modelId];
            return model ? model.getNumberOfCells() : 0;
        },
        setVisibleAnnotation(modelId: string, annotationId: number | null) {
            this.visibleAnnotation[modelId] = annotationId;
            if (annotationId) {
                const modelStore = useModelStore();
                const polydata = modelStore.dataIndex[modelId].getCellData();
                const arrayName = `Scalars-${annotationId}-${this.getAnnotation(annotationId)?.name}`;
                const scalarArray = polydata.getArrayByName(arrayName);
                if (scalarArray) {
                    polydata.setActiveScalars(arrayName)
                    scalarArray.modified();
                }
            }
            this.update();
        },
        /**
         * Remove all arrays for a specific model ID.
         * 
         * @param modelId The ID of the model from which to remove all arrays.
         */
        removeAllArrays(modelId: string) {
            const modelStore = useModelStore();
            const polydata = modelStore.dataIndex[modelId].getCellData();

            // Remove all arrays in the polydata
            polydata.removeAllArrays();
            polydata.modified();

            // Also remove the annotations related to this modelId
            this.annotations = this.annotations.filter(annotation => annotation.modelId !== modelId);

            this.update();
        },
        activateTool(): boolean {
            this.active = true;
            return true;
        },
        deactivateTool() {
            this.active = false;
        },
        serializeJSON(modelID: string): string {
            const modelStore = useModelStore();
            const polydata = modelStore.dataIndex[modelID].getCellData();
        
            if (!polydata) {
                throw new Error(`Model with ID ${modelID} not found.`);
            }
        
            const annotationsOutput = this.getAnnotationsByModelId(modelID).map(annotation => {
                const scalarArrayName = `Scalars-${annotation.id}-${annotation.name}`;
                const scalarArray = polydata.getArrayByName(scalarArrayName);
        
                if (!scalarArray) {
                    return null; // No scalar array for this annotation
                }
        
                const values = scalarArray.getData(); // Get the scalar values
                const cellAnnotationsMap: Record<number, number[]> = {};
        
                // Iterate over the values to map cell IDs to annotation values
                values.forEach((value, cellID) => {
                    if (value !== 0) { // Ignore the 0 annotation values
                        if (!cellAnnotationsMap[value]) {
                            cellAnnotationsMap[value] = [];
                        }
                        cellAnnotationsMap[value].push(cellID);
                    }
                });
        
                // Convert the map to an array format
                const annotations = Object.keys(cellAnnotationsMap).map(key => ({
                    value: parseInt(key, 10),
                    cellIDs: cellAnnotationsMap[key]
                }));
        
                return {
                    name: annotation.name,
                    annotations: annotations
                };
            }).filter(Boolean); // Filter out any null annotations
        
            // Return the JSON representation
            return JSON.stringify(annotationsOutput, null, 2); // Pretty-print with 2-space indentation
        },
        async serialize(state: StateFile) {
            const { labelMaps } = state.manifest;
            const { zip } = state;
          
            await Promise.all(
              Object.entries(this.annotations).map(async ([id, annotation]) => {
                const modelStore = useModelStore();
                const polydata = modelStore.dataIndex[annotation.modelId];
                const modelName = modelStore.metadata[annotation.modelId].name;
          
                if (!polydata) {
                  throw new Error(`Model with ID ${annotation.modelId} not found.`);
                }
          
                // Generate the file path for the label (VTP file)
                const labelPath = `surfaceAnnotations/${id}-${modelName}.vtp`;
          
                // Add entry to the manifest
                labelMaps.push({
                  id,
                  parent: annotation.modelId,
                  path: labelPath,
                });
          
                // Serialize the polydata to a VTP file
                const serializedData = await vtpWriter(polydata);
                zip.file(labelPath, serializedData);
              })
            );
          },
        /**
         * Remove a specific array by model ID and array ID.
         * 
         * @param modelId The ID of the model to which the array belongs.
         * @param arrayId The ID of the array to remove.
         */
        removeArray(modelId: string, arrayId: number) {

            const modelStore = useModelStore();
            const polydata = modelStore.dataIndex[modelId].getCellData();

            // Find the array by its name using the naming convention: "Scalars-[arrayId]-[name]"
            const annotation = this.annotations.find(annotation => annotation.id === arrayId);
            if (annotation) {
                const arrayName = `Scalars-${arrayId}-${annotation.name}`;
                const scalarArray = polydata.getArrayByName(arrayName);
                if (scalarArray) {
                    polydata.removeArray(arrayName);
                    polydata.modified();
                }
            }
        },

        /**
         * Set the brush size (1 to 10).
         * 
         * @param size The size of the brush.
         */
        setBrushSize(size: number) {
            if (size >= 1 && size <= 10) {
                this.brushSize = size;
            } else {
                console.warn('Brush size must be between 1 and 10.');
            }
        },

        /**
         * Set the label value (1 to 40).
         * 
         * @param value The label value for the brush.
         */
        setLabelValue(value: number) {
            if (value >= 1 && value <= 40) {
                this.labelValue = value;
            } else {
                console.warn('Label value must be between 1 and 40.');
            }
        },

        /**
         * Set the mode to either 'paint' or 'remove'.
         * 
         * @param mode The mode ('paint' or 'remove').
         */
        setMode(mode: Mode) {
            this.mode = mode;
        },

        update() {
            this.updated = !this.updated;
        },
    },
});