<template>
    <v-card width="350px">
      <v-container>
        <!-- Brush Size Slider -->
        <v-row no-gutters align="center">
          <v-col>
            <v-slider
              :model-value="brushSize"
              @update:model-value="setBrushSize"
              density="compact"
              hide-details
              label="Brush Size"
              min="1"
              max="10"
              step="1"
            >
              <template v-slot:append>
                <v-text-field
                  :model-value="brushSize"
                  @input="setBrushSize"
                  variant="underlined"
                  class="mt-n1 pt-0"
                  style="width: 40px"
                  hide-details
                  type="number"
                  min="1"
                  max="10"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>
  
        <!-- Label Value Slider -->
        <v-row no-gutters align="center">
          <v-col>
            <v-slider
              :model-value="labelValue"
              @update:model-value="setLabelValue"
              density="compact"
              hide-details
              label="Label Value"
              min="1"
              max="40"
              step="1"
            >
              <template v-slot:append>
                <v-text-field
                  :model-value="labelValue"
                  @input="setLabelValue"
                  variant="underlined"
                  class="mt-n1 pt-0"
                  style="width: 40px"
                  hide-details
                  type="number"
                  min="1"
                  max="40"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>
  
        <!-- Paint/Remove Switch -->
        <v-row no-gutters align="center">
          <v-col>
            <v-switch
              v-model="isDrawing"
              label="Remove / Paint"
              :append-icon="isDrawing ? 'mdi-brush' : 'mdi-eraser'"
              @click="toggleDrawing"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </template>
  
  <script lang="ts">
  import { computed, defineComponent } from 'vue';
  import { useSurface3DAnnotationStore } from '@/src/store/tools/surfaceDrawings';
  
  export default defineComponent({
    name: 'SurfaceAnnotationControls',
  
    setup() {
      const drawingTools = useSurface3DAnnotationStore();
  
      // Brush Size
      const brushSize = computed(() => drawingTools.brushSize);
      const setBrushSize = (size: number) => {
        drawingTools.setBrushSize(Number(size));
      };
  
      // Label Value (Color)
      const labelValue = computed(() => drawingTools.labelValue);
      const setLabelValue = (value: number) => {
        drawingTools.setLabelValue(Number(value));
      };
  
      // Paint / Remove Toggle
      const isDrawing = computed(() => drawingTools.mode === 'paint');
  
      const toggleDrawing = () => {
        if (isDrawing.value) {
          drawingTools.setMode('remove');
        } else {
          drawingTools.setMode('paint');
        }
      };
  
      return {
        brushSize,
        setBrushSize,
        labelValue,
        setLabelValue,
        isDrawing,
        toggleDrawing,
      };
    },
  });
  </script>
  
  <style scoped>
  .mt-n1 {
    margin-top: -0.25rem;
  }
  
  .pt-0 {
    padding-top: 0;
  }
  </style>