<template>
  <div>
    <v-row no-gutters justify="space-between" align="center" class="mb-1">
      <v-col cols="6">
        <v-checkbox class="ml-3" label="All visible" v-model="allVisible" density="compact" hide-details
          @change="toggleAllVisibility" />
      </v-col>
      <v-col cols="6" align-self="center" class="d-flex justify-end">
        <v-btn icon variant="text" @click="previousModel" class="mr-2">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon variant="text" @click="nextModel">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-virtual-scroll :items="surfaceModels" max-height="600" item-height="60">
      <template v-slot:default="{ item: model }">
        <v-list-item :key="model.id" :value="model.id">
          <template v-slot:prepend>
            <v-list-item-action start @click="toggleVisibility(model.id)">
              <v-icon>{{ model.metadata.visible ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
            </v-list-item-action>
          </template>

          <v-list-item-title>{{ model.metadata.name }}</v-list-item-title>

          <v-list-item-subtitle>
            Vertices: {{ model.metadata.vertices }} | Faces: {{ model.metadata.faces }}
          </v-list-item-subtitle>

          <template v-slot:append>

            <v-menu top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <div>
                  <v-btn v-bind="props" color="grey-lighten-1" icon="mdi-circle" variant="text"><v-icon
                      :color="getColorString(model.metadata.displayColor)"></v-icon></v-btn>
                </div>
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker v-model="model.metadata.displayColor" @update:modelValue="updateColor(model.id)"
                    mode="rgba" hide-inputs></v-color-picker>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-btn icon="mdi-delete" variant="text" @click="deleteModel(model.id)">
              <v-icon>mdi-delete</v-icon>
              <v-tooltip location="top" activator="parent">Delete</v-tooltip>
            </v-btn>

          </template>

        </v-list-item>

      </template>
    </v-virtual-scroll>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useModelStore } from '../store/datasets-models';

export default defineComponent({
  name: 'ShapeModule',
  setup() {
    const modelStore = useModelStore();

    const surfaceModels = computed(() => {
      return modelStore.idList
        .filter(id => !modelStore.metadata[id].model) // Filter for statistical models
        .map(id => ({
          id,
          metadata: modelStore.metadata[id],
        }));
    });

    const allVisible = computed(() => {
      return surfaceModels.value.every(model => model.metadata.visible);
    });

    const selected = ref<string[]>([]);
    const selectedSome = computed(() => selected.value.length > 0);

    const isSelected = (id: string) => {
      return selected.value.includes(id);
    };

    const toggleSelection = (id: string) => {
      if (isSelected(id)) {
        const index = selected.value.indexOf(id);
        selected.value.splice(index, 1);
      } else {
        selected.value.push(id);
      }
    };

    function toggleAllVisibility() {
      let visible = true;
      surfaceModels.value.forEach(model => {
        if (!model.metadata.visible) {
          visible = false;
        }
      });

      surfaceModels.value.forEach(model => {
        model.metadata.visible = !visible;
      });
    }

    function toggleVisibility(id: string) {
      const isVisible = modelStore.metadata[id].visible;
      modelStore.metadata[id].visible = !isVisible;
    }

    function updateVisibility() {
      modelStore.idList.forEach(id => {
        modelStore.metadata[id].visible = (id === modelStore.currentId);
      });
    }

    function nextModel() {
      modelStore.incrementIndex();  // Increment the index
      updateVisibility();
    }

    function previousModel() {
      modelStore.decrementIndex();  // Decrement the index
      updateVisibility();
    }

    function updateColor(id: string) {
      modelStore.refreshDisplay();
    }

    function deleteModel(id: string) {
      modelStore.deleteData(id);  // Call the store method to delete the model
      // Optionally, handle any additional cleanup or UI feedback
    }

    function getColorString(color: { r: number; g: number; b: number; a: number; }) {
      return `rgb(${color.r}, ${color.g}, ${color.b})`;
    }

    return {
      nextModel,
      previousModel,
      allVisible,
      surfaceModels,
      getColorString,
      toggleVisibility,
      toggleAllVisibility,
      deleteModel,
      selectedSome,
      isSelected,
      toggleSelection,
      updateColor,
    };
  },
});
</script>

<style scoped>
.datasetPanel:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* vuetify override */
.datasetPanel:before,
.subpanel:before {
  box-shadow: none;
}

.panelContent {
  /* for positioning the color group absolutely */
  position: relative;
}

/* vuetify override */
.panelContent> :global(.v-expansion-panel-content__wrap) {
  padding: 0;
}

.subpanel {
  padding-left: 20px;
}

.subpanel .panelContent {
  padding: 4px 0;
}

.panelContent .subpanel:first-child {
  border-top: thin solid rgba(0, 0, 0, 0.1);
}

/* vuetify override */
/* This sets flex-grow to 0 for header contents */
.panelHeader> :not(:global(.v-expansion-panel-header__icon)) {
  flex: unset;
}

.headerMenuButton {
  /* maintain icon width */
  flex: 0 0 auto !important;
}

.subpanelHeader {
  /* the 12px is to line up the expansion panel hide/show icons */
  padding: 2px 12px 2px 4px;
  /* revert subpanel height to non-active height */
  min-height: 48px !important;
}
</style>