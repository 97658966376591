import { defineStore } from 'pinia';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';
import vtkPoints from '@kitware/vtk.js/Common/Core/Points';
import { reactive } from 'vue';
import { useIdStore } from '@/src/store/id';
import { removeFromArray } from '../utils';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import { ImageMetadata } from '../types/image';
import { getLPSDirections } from '../utils/lps';
import { vec3 } from 'gl-matrix';
import { DatasetType, StateFile } from '../io/state-file/schema';
import { vtpWriter } from '../io/vtk/async';
import { useFileStore } from './datasets-files';
import { serializeData } from '../io/state-file/utils';
import '../utils/kdTree';
import { kdTree } from '../utils/kdTree';
// Updated Metadata interface to include modeArray
interface Metadata {
  name: string;
  visible: boolean;
  vertices: number;
  faces: number;
  model: boolean;
  displayColor: { r: number; g: number; b: number; a: number };
  modeArray: number[]; // Array of zeros for SSM models
}

interface KdTreeEntry {
  tree: kdTree | null; // The kdTree structure
  computed: boolean;   // Indicates if the kdTree has been computed
}

interface State {
  idList: string[]; // list of IDs
  dataIndex: Record<string, vtkPolyData>; // ID -> VTK object
  references: Record<string, vtkPoints>; // ID -> VTK object
  kdTreeIndex: Record<string, KdTreeEntry>;
  metadata: Record<string, Metadata>; // ID -> metadata
  currentIndex: number;
  metaIdChanged: number;
  dimensionMetadata : ImageMetadata | null;
}

export const useModelStore = defineStore('models', {
  state: (): State => ({
    idList: [],
    dataIndex: {},
    references: {},
    kdTreeIndex: {},
    metadata: {},
    currentIndex: 0,
    metaIdChanged: 0,
    dimensionMetadata : null,
  }),
  getters: {
    visibleIds(): string[] {
      return this.idList.filter(id => this.metadata[id].visible);
    },
    currentId(): string | null {
      return this.idList[this.currentIndex] || null;
    },
    getDisplayColor: (state) => (id: string) => {
      return state.metadata[id]?.displayColor || { r: 255, g: 255, b: 255, a: 1 }; // Default to white if not set
    },
  },
  actions: {
    findIndexByPolyData(polyData: vtkPolyData): string | null {
      for (const [id, data] of Object.entries(this.dataIndex)) {
        if (data === polyData) {
          return id;
        }
      }
      return null;
    },
    buildKdTree(id: string) {

      const polydata = this.dataIndex[id];

      const points = polydata.getPoints().getData(); // Get points data
      const pointArray = [];

      for (let i = 0; i < points.length; i += 3) {
        pointArray.push({ x: points[i], y: points[i + 1], z: points[i + 2] });
      }

      const t = new kdTree(
        pointArray,
        (a: {x: number,y:number,z:number}, b: {x: number,y:number,z:number}) => Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2 + (a.z - b.z) ** 2),
        ['x', 'y', 'z']
      );

      this.kdTreeIndex[id] = {computed: true, tree: t};

      console.log(t);

    },

    buildKdTreeFromPolyData(polyData: vtkPolyData)  {
      const kdtree = new kdTree(polyData.getPoints());
    },
    addVTKPolyData(name: string, polyData: vtkPolyData) {
      const id = useIdStore().nextId();
      this.idList.push(id);
      this.dataIndex[id] = polyData;
      this.references[id] = polyData.getPoints();

      polyData.buildLinks();
      polyData.modified();

      const numCells = polyData.getNumberOfCells();
      const numVertices = polyData.getNumberOfPoints();

      this.metadata[id] = reactive({
        name: name,
        visible: true,
        model: false,
        vertices: numVertices,
        faces: numCells,
        displayColor: { r: 255, g: 255, b: 255, a: 1 },
        modeArray: [], // Initialize modeArray
      });

      // Call the renamed function to process SSM models
      this.processSSMModel(polyData, this.metadata[id]);
      this.$proxies.addData(id, polyData);

      // Update the metadata with the new dimensions

      this.refreshModelMetadata();

      return id;
    },

    async serialize(stateFile: StateFile) {
      const fileStore = useFileStore();
      // We want to filter out volume images (which are generated and don't have
      // input files in fileStore with matching imageID.)
      const dataIDs = this.idList.filter((id) => id in fileStore.byDataID);

      await serializeData(stateFile, dataIDs, DatasetType.MODEL);
    },

    createImageFromBounds(bounds: number[], stepSize = 1.0) {
      // Calculate the range of the bounds in each direction
      const xRange = bounds[1] - bounds[0]; // xmax - xmin
      const yRange = bounds[3] - bounds[2]; // ymax - ymin
      const zRange = bounds[5] - bounds[4]; // zmax - zmin
    
      // Calculate the resolution based on the step size
      const resolution = [
        Math.ceil(xRange / stepSize),  // Number of voxels in x-direction
        Math.ceil(yRange / stepSize),  // Number of voxels in y-direction
        Math.ceil(zRange / stepSize),  // Number of voxels in z-direction
      ];
    
      // Create a new vtkImageData instance
      const pseudo_image = vtkImageData.newInstance();
      
      // Set the image extent based on resolution
      pseudo_image.setExtent(0, resolution[0] - 1, 0, resolution[1] - 1, 0, resolution[2] - 1);
      
      // Set the image spacing (fixed step size in each direction)
      pseudo_image.setSpacing([stepSize, stepSize, stepSize]);
      
      // Set the origin based on the bounds (xmin, ymin, zmin)
      pseudo_image.setOrigin([bounds[0], bounds[2], bounds[4]]);
    
      return pseudo_image;
    },

    refreshModelMetadata() {
      if (this.idList.length === 0) return;

      // Initialize min/max bounds to large/small values
      const overallBounds = [Infinity, -Infinity, Infinity, -Infinity, Infinity, -Infinity];

      // Calculate the min/max bounds across all polydata models
      for (const id of this.idList) {
        const bounds = this.dataIndex[id].getBounds();
        overallBounds[0] = Math.min(overallBounds[0], bounds[0]); // xmin
        overallBounds[1] = Math.max(overallBounds[1], bounds[1]); // xmax
        overallBounds[2] = Math.min(overallBounds[2], bounds[2]); // ymin
        overallBounds[3] = Math.max(overallBounds[3], bounds[3]); // ymax
        overallBounds[4] = Math.min(overallBounds[4], bounds[4]); // zmin
        overallBounds[5] = Math.max(overallBounds[5], bounds[5]); // zmax
      }

      // Create image data from the overall bounds
      const imageData = this.createImageFromBounds(overallBounds);
      
      // Update the dimension metadata with the new bounds
      const metadata: ImageMetadata = {
        name: 'Model_Image_Dimensions',
        dimensions: imageData.getDimensions() as vec3,
        spacing: imageData.getSpacing() as vec3,
        origin: imageData.getOrigin() as vec3,
        orientation: imageData.getDirection(),
        lpsOrientation: getLPSDirections(imageData.getDirection()),
        worldBounds: imageData.getBounds(),
        worldToIndex: imageData.getWorldToIndex(),
        indexToWorld: imageData.getIndexToWorld(),
      };
      this.dimensionMetadata = metadata;
    },
    // Renamed the function from checkForModelFlag to processSSMModel
    processSSMModel(polyData: any, metadata: Metadata) {
      const pointData = polyData.getPointData();
      let hasModel = false;
      let modeCount = 0;

      // Iterate over all arrays in point data
      for (let i = 0; i < pointData.getNumberOfArrays(); i++) {
        const arrayName = pointData.getArrayName(i);

        // If the array name contains 'mode_', it indicates an SSM model
        if (arrayName && arrayName.startsWith('mode_')) {
          hasModel = true;
          modeCount++;
        }
      }

      // Update metadata with the model flag
      metadata.model = hasModel;

      // If the model exists, create an array of zeros based on `modeCount`
      if (hasModel) {
        metadata.modeArray = Array(modeCount).fill(0); // Create array of zeros
      }
    },
    setCurrentIndex(index: number) {
      if (index >= 0 && index < this.idList.length) {
        this.currentIndex = index;
      }
    },
    getModelName(id: string) {
      return this.metadata[id]?.name;
    },
    deleteData(id: string) {
      if (id in this.dataIndex) {
        delete this.dataIndex[id];
        delete this.metadata[id];
        removeFromArray(this.idList, id);
      }
    },
    refreshDisplay() {
      this.metaIdChanged = (this.metaIdChanged + 1) % 20;
    },
    setDisplayColor(id: string, rgbColor: { r: number; g: number; b: number; a: number }) {
      if (id in this.metadata) {
        this.metadata[id].displayColor = rgbColor;
        this.refreshDisplay();
      }
    },
    setVisibility(id: string, flag: boolean) {
      if (id in this.metadata) {
        this.metadata[id].visible = flag;
        this.refreshDisplay();
      }
    },
    incrementIndex() {
      this.currentIndex = (this.currentIndex + 1) % this.idList.length;
    },
    decrementIndex() {
      this.currentIndex = (this.currentIndex - 1 + this.idList.length) % this.idList.length;
    },
  },
});