import { defineStore } from 'pinia';
import axios, { AxiosInstance } from 'axios';
import cookies from 'js-cookie';
import {
  importDataSources,
  convertSuccessResultToDataSelection,
} from '@/src/io/import/importDataSources';
import { useDatasetStore } from '@/src/store/datasets';
import { fileToDataSource } from '../io/import/dataSource';
import { useLandmarkStore } from '@/src/store/tools/landmarks'; // Adjust the path as needed
import { useLineStore } from './tools/lines';
import { useAngleStore } from './tools/angles';
import { useModelStore } from './datasets-models';
import { useSplineStore } from './tools/splines';
import { useSurfaceMeasurementStore } from './tools/surfaceMeasurements';
import { ProjectType } from './tools/types'
import { useGroupStore } from './datasets-groups';

interface GirderConfig {
  version: string;
  timeout: number;
}

interface Project {
  name: string;
  id: string;
  type: ProjectType;
  update: boolean,
}

interface Reference extends Project {
  type : ProjectType;
  anatomy: string;
}

interface Cohort extends Project {
  type : ProjectType;
  anatomy: string;
  items: string[];
  realVsVirtual: string;
  prepared: boolean;
}

interface SingleCase extends Project {
  meta: {
    input_items: string[];
    jobId: string;
    output_items: string[];
    task: string;
    anatomy: string;
  };
  state: string;
  type : ProjectType;
}

interface Progress {
  current: number;
  message: string;
  notificationId: string;
}

interface Job {
  jobId: string;
  log: string;
  progress : Progress;
  status: string;
}

interface FileGirderPath {
  id: string;
  path: string;
  type: string;
}

// Define the store
export const useGirderStore = defineStore('girder', {
  state: () => ({
    config: {
      version: 'v1',
      timeout: 120000,
    } as GirderConfig,
    host: 'https://v2202410237021291825.nicesrv.de',
    port: '',
    key: "",
    connected: false,
    rest: null as AxiosInstance | null,
    cases: {} as { [id: string]: Project },
    references: {} as { [id: string]: Reference },
    caseFolder: null as { _id: string; name: string } | null,
    referenceFolder: null as { _id: string; name: string } | null,
    cohortsFolder: null as { _id: string; name: string } | null,
    cohorts: {} as { [id: string]: Cohort },
    collection: null as { _id: string; name: string } | null,
    loadedCase: null as Project | null,
    ALLOWED_STATES: ['state_empty', 'state_image_uploaded', 'state_image_processed'],
    inputItem: null,
    outputItem: null,
    girderPaths: [] as FileGirderPath[],
    datasetStore: useDatasetStore(),
  }),
  actions: {
    initializeAxios() {
      this.rest = axios.create({
        baseURL: `${this.host}:${this.port}/api/v1`,
        timeout: this.config.timeout,
      });
      this.addRequestDefaultTokenFromCookies();
    },
    addRequestDefaultTokenFromCookies() {
      this.rest.defaults.headers.common['Girder-Token'] = cookies.get('girderToken');
    },
    async login(apiKey: string, duration: number = 5) {
      if(apiKey === '') {
        return;
      }
      try {
        const { data } = await this.rest.post('/api_key/token', null, {
          params: {
            key: apiKey,
            duration
          }
        });
        // Save token in cookies
        cookies.set('girderToken', data.authToken.token, { sameSite: 'strict' });
        this.addRequestDefaultTokenFromCookies();
        this.connected = true;
        await this.searchCollection();
        await this.findProjectFolders();
        await this.fetchAnatomies();
        await this.listCases();

      } catch (error) {
        this.connected = false;
        console.error('Login with API key failed:', error);
      }
    },
    async searchCollection() {
      try {
        const response = await this.rest.get('/collection', {
          params: {
            text: 'shapemeans_suite',
            limit: 2,
            offset: 0,
            sort: 'name',
            sortdir: 1,
          },
        });

        // Check if folder with name "cases" exists and store it
        const collection = response.data.find(col => col.name === 'shapemeans_suite');
        if (collection) {
          this.collection = { _id: collection._id, name: collection.name };
        } else {
          console.warn("No collection found.");
        }

      } catch (error) {
        console.error('Failed to search for collection shapemeans_suite', error);
      }
    },
    async findProjectFolders() {
      try {
        if(this.collection){
          this.caseFolder = await this.searchFolderInCollection("cases",this.collection._id)
          this.referenceFolder = await this.searchFolderInCollection("references",this.collection._id)
          this.cohortsFolder = await this.searchFolderInCollection("cohorts",this.collection._id)
        }
      } catch (error) {
        console.error('Failed to search for "cases" folder:', error);
      }
    },
    async searchFolderInCollection(name: string, collection: string) {
      try {
        const response = await this.rest.get('/folder', {
          params: {
            parentType: 'collection',
            parentId: collection,
            name: name,
            limit: 2,
            offset: 0,
            sort: 'lowerName',
            sortdir: 1,
          },
        });

        // Check if folder with name "cases" exists and store it
        const foundFolder = response.data.find(folder => folder.name === name);
        if (foundFolder) {
          return { _id: foundFolder._id, name: foundFolder.name };
        } else {
          console.warn("No 'references' folder found.");
          return null;
        }

      } catch (error) {
        console.error('Failed to search for "references" folder:', error);
        return null;
      }
    },
    setServerById(serverId: string | null = null) {
      if(serverId == 'dev'){
        this.host = 'https://g3.v2202410237021291825.nicesrv.de';
        this.port = '';
        this.key = 'lMtAMWhN4Yk3yCiQYy8sNIptjht0t60TguFDYjlN';
        this.initializeAxios();
        this.login(this.key,5);
      } else {
        this.host = `https://${serverId}.v2202410237021291825.nicesrv.de`;
      }
    },
    setLoadedCaseByID(caseId: string) {
      this.loadedCase = this.cases[caseId] || null;
    },
    setLoadedCase(c: Project) {
      this.loadedCase = c;
    },
    async updateCaseState(caseId: string, newState: string) {

      if (!this.ALLOWED_STATES.includes(newState)) {
        console.error(`Invalid state. Allowed states are ${this.ALLOWED_STATES.join(", ")}`);
        return;
      }

      const config = {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      };

      try {
        const metadata = { state: newState };
        const { data } = await this.rest.put(`/folder/${caseId}/metadata`, metadata, config);
        // You might also want to update the local state here
      } catch (error) {
        console.error('Failed to update case state:', error);
      }
    },

    async submitCohortJob(cohort: Cohort) {
      try {
        // Create a job for the cohort using the cohort details
        const config = {
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
          params: {
            title: `Create Cohort Job for ${cohort.name}`,
            type: 'Create cohort',
            public: false,
            kwargs: JSON.stringify({
              caseId: cohort.id,
              outputFolder: cohort.id,
              baseFolder: this.caseFolder?._id,
            }),
          },
        };
    
        // Step 1: Create the cohort job via API
        const { data: createdJob } = await this.rest.post('/job', null, config);

        console.log(createdJob);
    
        // Step 2: Update the job to set its status to QUEUED
        const updateConfig = {
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
          params: {
            status: '1',
            progressTotal: 0,
            progressCurrent: 0,
            progressMessage: 'Cohort Job queued',
            notify: true,
          },
        };
    
        const { data: updatedJob } = await this.rest.put(`/job/${createdJob._id}`, null, updateConfig);
    
        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        };
        const metadata = {
          state: this.ALLOWED_STATES[1],
          jobId: createdJob._id,
        };
    
        await this.rest.put(`/folder/${cohort.id}/metadata`, metadata, metadataConfig);
    
        return updatedJob;
      } catch (error) {
        console.error('Failed to create cohort job:', error);
        throw error;
      }
    },    

    async createJob(caseId: string, task: string) {
        try {
          const config = {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            params: {
              title: `Job for Case ${caseId}`,
              type: task,
              public: false,
              kwargs: JSON.stringify({
                caseId: caseId,
                outputFolder: caseId
              }),
            },
          };

          const { data: createdJob } = await this.rest.post('/job', null, config);


          // Step 2: Update the job to set status to QUEUED
          const updateConfig = {
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            params: {
               status: '1', // Assuming '1' represents QUEUED status
               progressTotal: 0,
               progressCurrent: 0,
               progressMessage: 'Job queued',
               notify: true
            },
          };

          const { data: updatedJob } = await this.rest.put(`/job/${createdJob._id}`, null, updateConfig);

          return updatedJob;
        } catch (error) {
          console.error('Failed to create job:', error);
          if (error.response) {
            const { data } = error.response;
            console.error('Error details (if available):', data);
          }
          throw error;
        }
    },

    async storeGirderPaths(loadResults: any[], allItems: any[]) {

      // Ensure that loadResults and allItems have the same length
      if (loadResults.length !== allItems.length) {
        console.error("Mismatch between loadResults and allItems length");
        return;
      }

      // Iterate over the results and items to store or update the mappings
      loadResults.forEach((result, index) => {
        if (result.ok && result.data.length > 0) {
          const dataID = result.data[0].dataID;
          const type = result.data[0].dataType;
          const path = allItems[index]._id;

          // Find the existing entry by the path
          const existingEntryIndex = this.girderPaths.findIndex(entry => entry.path === path);

          if (existingEntryIndex !== -1) {
            // If entry exists, update it
            this.girderPaths[existingEntryIndex] = { id: dataID, path, type };
          } else {
            // If entry doesn't exist, add a new one
            this.girderPaths.push({ id: dataID, path, type });
          }
        } else {
          console.warn(`Skipping invalid load result at index ${index}`);
        }
      });
    },

    async createDicomFolder(folderID: string): Promise<string> {
      try {
          const itemConfig = {
              headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          };
          const { data } = await this.rest.post(`/item?folderId=${folderID}&name=DICOM`, {}, itemConfig);
          return data._id;
      } catch (error) {
          console.error('Failed to create DICOM item:', error);
          throw error;
      }
    },

    async createItem(folderID: string, fileName: string): Promise<string> {
      try {
          const itemConfig = {
              params: { folderId: folderID, name: fileName },
              headers: { 'Content-Type': 'application/json' },
          };
          const { data } = await this.rest.post('/item', null, itemConfig);
          return data._id;
      } catch (error) {
          console.error(`Failed to create item for file ${fileName}:`, error);
          throw error;
      }
    },

    async initiateUpload(file: File, itemFolderID: string): Promise<string> {
      try {
          const initConfig = {
              params: {
                  parentType: 'item',
                  parentId: itemFolderID,
                  name: file.name,
                  size: file.size,
                  mimeType: file.type,
              },
          };
  
          const { data } = await this.rest.post('/file', null, initConfig);
          return data._id;
      } catch (error) {
          console.error(`Failed to initiate upload for file ${file.name}:`, error);
          throw error;
      }
  },

  async uploadFileInChunks(
    file: File,
    itemFolderID: string,
    totalUploadedRef: { value: number },
    totalSize: number,
    progressCallback: (progress: number) => void
) {
    let startByte = 0;

    const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB

    // Request initial upload ID
    const uploadId = await this.initiateUpload(file, itemFolderID);

    while (startByte < file.size) {
        const endByte = Math.min(startByte + CHUNK_SIZE, file.size);
        const chunk = file.slice(startByte, endByte);

        try {
            // Sending chunk as body with parameters in the query string
            const query = `uploadId=${uploadId}&offset=${startByte}`;
            const uploadConfig = {
                headers: { 'Content-Type': file.type }, // Ensure the content-type matches the file type
                data: chunk, // Chunk is sent as the request body
            };

            await this.rest.post(`/file/chunk?${query}`, chunk, uploadConfig);

            totalUploadedRef.value += chunk.size;
            startByte = endByte;

            // Update progress
            const progress = Math.round((totalUploadedRef.value * 100) / totalSize);
            progressCallback(progress);
        } catch (error) {
            console.error(`Failed to upload chunk for file ${file.name} at offset ${startByte}:`, error);
            throw error;
        }
    }
},

    async uploadFiles(
      files: File[],
      folderID: string,
      dicomFolderID: string,
      totalSize: number,
      progressCallback: (progress: number) => void
  ): Promise<string[]> {
      const inputItems: string[] = [];
      const totalUploadedRef = { value: 0 };
  
      for (const file of files) {
          const isDicomFile = file.name.endsWith('.dcm');
          const targetFolderID = isDicomFile ? dicomFolderID : folderID;
  
          let itemFolderID: string;
          if (!isDicomFile) {
              try {
                  itemFolderID = await this.createItem(folderID, file.name);
                  inputItems.push(itemFolderID);
              } catch {
                  continue; // Skip this file if the item creation failed
              }
          } else {
              itemFolderID = dicomFolderID;
          }
  
          try {
              await this.uploadFileInChunks(file, itemFolderID, totalUploadedRef, totalSize, progressCallback);
          } catch {
              continue; // Skip this file if the upload failed
          }
      }
  
      return inputItems;
    },

    async uploadFile(files: File[], folderID: string, task: string, progressCallback: (progress: number) => void) {
      let dicomFolderID = folderID;
      const totalSize = files.reduce((acc, file) => acc + file.size, 0);
      const dicomFiles = files.filter((file) => file.name.endsWith('.dcm'));
  
      if (dicomFiles.length > 0) {
          try {
              dicomFolderID = await this.createDicomFolder(folderID);
          } catch {
              throw new Error('Failed to prepare DICOM folder.');
          }
      }
  
      const inputItems = await this.uploadFiles(files, folderID, dicomFolderID, totalSize, progressCallback);

      if (dicomFiles.length > 0) {
        inputItems.push(dicomFolderID);
      }
  
      this.updateCaseState(folderID, this.ALLOWED_STATES[1]);
  
      progressCallback(100); // Ensure progress is set to 100% at the end
  
      return inputItems;
   },
  
    async createJobWithInputItems(inputItems: string[], task: string, baseTask: string, folderID: string) {

      try {

        const jobData = await this.createJob(folderID, task);

        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };
        const metadata = {
          state: this.ALLOWED_STATES[1],
          input_items: JSON.stringify(inputItems),
          task: baseTask,
          jobId: jobData._id
        };
        await this.rest.put(`/folder/${folderID}/metadata`, metadata, metadataConfig);
      } catch (error) {
        console.error('Failed to update case metadata:', error);
      }

    },

    async updateMetadata(itemId: string,metadata: {}) {
      try {
        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };

        // Send the PUT request to update metadata for the item
        await this.rest.put(`/item/${itemId}/metadata`, metadata, metadataConfig);

      } catch (error) {
        console.error(`Failed to update metadata for item ${itemId}:`, error);
      }
    },

    async fetchAnatomies() {
      try {
        const { data } = await this.rest.get('/case/anatomies'); // Ensure this endpoint is correct
        this.anatomies = data.map((anatomy: any) => ({
          text: anatomy.anatomy,
          value: anatomy.anatomy
        }));
      } catch (error) {
        console.error('Failed to fetch anatomies:', error);
      }
    },

    async getJob(jobId: string) {
      try {
        const config = { /* ... */ };
        const response = await this.rest.get(`/job/${jobId}`, config);

        if (response.status === 200) {
          const jobData = response.data;
          return transformJobData(jobData);
        }

        function transformJobData(jobData: any): Job {
          return {
            jobId: jobData._id,
            log: jobData.log,
            progress: jobData.progress,
            status: jobData.status,
          };
        }

        if (!response.data) {
          const emptyJob = {
            jobId: '',
            log: '',
            progress: {} as Progress,
            status: '',
          } as Job;
          return emptyJob;
        }
      } catch (error) {
        throw error;
      }
    },

    async listProjects(){
      this.listReferences();
      this.listCases();
      this.listCohorts();
    },

    async listReferences() {
      // Ensure reference folder is set
      if (!this.referenceFolder) {
        console.warn('No reference folder set');
        return;
      }
    
      // Configure request headers and parameters
      const config = {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        params: {
          folderId: this.referenceFolder._id,
        },
      };
    
      try {
        // Perform the API call to get references within the folder
        const { data } = await this.rest.get('/item', config);
    
        // Update the references state with the fetched data
        this.references = data.reduce((acc: { [id: string]: Reference }, referenceData: any) => {
          const anatomy = referenceData.meta.anatomy || '';
    
          const referenceObj: Reference = {
            name: anatomy,
            id: referenceData._id,
            anatomy: anatomy,
            type: ProjectType.Reference,
            update: false,
          };
    
          // Add each reference to the accumulator object using _id as the key
          acc[referenceData._id] = referenceObj;
          return acc;
        }, {});
    
      } catch (error) {
        console.error('Failed to list references:', error);
      }
    },

    async listCohorts() {
      // Ensure reference folder is set
      if (!this.cohortsFolder) {
        console.warn('No cohort folder set');
        return;
      }
    
      // Configure request headers and parameters
      const config = {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        params: {
          parentType: 'folder',
          parentId: this.cohortsFolder._id,
        },
      };
    
      try {
        // Perform the API call to get references within the folder
        const { data } = await this.rest.get('/folder', config);
    
        // Update the references state with the fetched data
        this.cohorts = data.reduce((acc: { [id: string]: Reference }, referenceData: any) => {
          const anatomy = referenceData.meta.anatomy || '';
          const realOrVirtual = referenceData.meta.type || '';
          const items = referenceData.meta.output_items ? JSON.parse(referenceData.meta.output_items) : [];
          const prepared = referenceData.meta.prepared === 'true';
    
          const cohort: Cohort = {
            name: referenceData.name,
            id: referenceData._id,
            anatomy: anatomy,
            realVsVirtual : realOrVirtual,
            type: ProjectType.Cohort,
            items: items,
            prepared: prepared,
            update: false,
          };
    
          // Add each reference to the accumulator object using _id as the key
          acc[referenceData._id] = cohort;
          return acc;
        }, {});
    
      } catch (error) {
        console.error('Failed to list references:', error);
      }
    },

    async listCases() {

      if(!this.caseFolder){
        console.warn('No case folder set');
        return;
      }

      const config = {
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        params: {
          parentType: 'folder',
          parentId: this.caseFolder?._id,
        },
      };

      try {
        const { data } = await this.rest.get('/folder/', config);

        // Update the cases state with the new map
        this.cases = data.reduce((acc: { [id: string]: Project }, caseData: any) => {
          const inputItems = caseData.meta.input_items ? JSON.parse(caseData.meta.input_items) : [];
          const outputItems = caseData.meta.output_items ? JSON.parse(caseData.meta.output_items) : [];
          const jobId = caseData.meta.jobId || '';
          const anatomy = caseData.meta.anatomy || '';

          // Determine the state based on the conditions
          let state = 'state_empty';
          if (outputItems.length > 0) {
            state = 'state_image_processed';
          } else if (inputItems.length > 0 || jobId) {
            state = 'state_image_uploaded';
          }

          const caseObj: SingleCase = {
            name: caseData.name,
            id: caseData._id,
            meta: {
              input_items: inputItems,
              jobId: jobId,
              output_items: outputItems,
              task: caseData.meta.task || '',
              anatomy: anatomy,
            },
            update: false,
            state: state, // Add the state property
            type: ProjectType.SingleCase,
          };
          acc[caseData._id] = caseObj;
          return acc;
        }, {});

      // Refresh loadedCase if it's found in the refreshed cases
      if (this.loadedCase) {
        const refreshedCase = this.cases[this.loadedCase.id];

        // Check if the loaded case has changed
        if (refreshedCase && JSON.stringify(refreshedCase) !== JSON.stringify(this.loadedCase)) {
          this.loadedCase = refreshedCase;
        }
      }

      } catch (error) {
        console.error('Failed to list cases:', error);
      }
    },
    async loadAnnotation(loadResult: any, meta: any) {

      if (loadResult.data[0]?.dataType !== 'model') return;

      const landmarkStore = useLandmarkStore();
      const lineStore = useLineStore();
      const splineStore = useSplineStore();
      const angleStore = useAngleStore();
      const modelStore = useModelStore();
      const allMeasurements = useSurfaceMeasurementStore();
      const groupStore = useGroupStore();
    
      if (!loadResult.ok || loadResult.data.length === 0) return;
    
      const dataID = loadResult.data[0].dataID;
      const measurements = JSON.parse(meta.meta.measurements || '[]');
    
      // Set model properties
      if (meta.meta.color) {
        const [r, g, b, a] = JSON.parse(meta.meta.color || '[]');
        modelStore.setDisplayColor(dataID, { r, g, b, a });
      }
    
      if (meta.meta.visibility) {
        modelStore.setVisibility(dataID, meta.meta.visibility === '1');
      }
    
      if (meta.meta.gp_model) {
        modelStore.setDisplayColor(dataID, { r: 0, g: 255, b: 255, a: 1 });
      }
    
      // Create a group for the loaded model
      groupStore.createGroup(dataID);
    
      // Set measurements
      measurements.forEach((measurement) => {
        const points = measurement.points3DForVisualization || [];
        const knotPoints = measurement.knotPoints || [];
        const landmarkIds: number[] = [];
        const visualizationPoints: number[][] = [];
        const isGeodesicDist = measurement.measurementType === "GeodesicDist";
    
        points.forEach((point, index) => {
          if (isGeodesicDist) {
            visualizationPoints.push([point.x, point.y, point.z]);
          } else {
            let landmarkName;
            if (measurement.measurementType === "Landmark") {
              landmarkName = `${measurement.name}`;
            } else {
              landmarkName = `${measurement.name}_point${index + 1}`;
            }
            const landmark = landmarkStore.addLandmark(point.x, point.y, point.z, dataID, landmarkName, true, true, false);
            landmarkIds.push(landmark.id);
            groupStore.addLandmarkToGroup(dataID, landmark.id);
          }
        });
    
        if (isGeodesicDist && knotPoints) {
          knotPoints.forEach((point, index) => {
            const landmark = landmarkStore.addLandmark(point.x, point.y, point.z, dataID, `${measurement.name}_point${index + 1}`, false, false, true);
            landmarkIds.push(landmark.id);
            groupStore.addLandmarkToGroup(dataID, landmark.id);
          });
        }
    
        switch (measurement.measurementType) {
          case "Angle":
            if (landmarkIds.length === 3) {
              const angle = angleStore.addAngle(measurement.name, landmarkIds[0], landmarkIds[1], landmarkIds[2], measurement.instanceValue);
              groupStore.addAngleToGroup(dataID, angle.id);
            }
            break;
          case "EuclideanDist":
            if (landmarkIds.length === 2) {
              const line = lineStore.addLine(measurement.name, landmarkIds[0], landmarkIds[1], measurement.instanceValue);
              groupStore.addLineToGroup(dataID, line.id);
            }
            break;
          case "GeodesicDist":
            const spline = splineStore.addSpline(measurement.name, landmarkIds, false, measurement.instanceValue, visualizationPoints);
            groupStore.addSplineToGroup(dataID, spline.id);
            break;
        }
      });
    
      modelStore.refreshDisplay();
      allMeasurements.refresh();
    
      // Log the created group for debugging
      console.log(groupStore.groups[dataID]);
    },
    
    async loadBatchAnnotations(loadResults: any[], metadata: any[]) {
      loadResults.forEach((loadResult, index) => {
        this.loadAnnotation(loadResult, metadata[index]);
      });
    },
    async loadItem(itemID: string) {
      try {
        // Step 1: Fetch metadata for the item
        const { data: itemMetadata } = await this.rest.get(`/item/${itemID}`);
        
        // Step 2: Download the item
        const downloadedFile = await this.downloadItem(itemID);
    
        // Step 3: Convert the downloaded file to a data source
        const dataSource = await fileToDataSource(downloadedFile);
    
        // Step 4: Import data source and load annotations
        const loadResult = await importDataSources([dataSource]);
    
        if (!loadResult || loadResult.length === 0 || !loadResult[0].ok) {
          throw new Error('Failed to load data source');
        }
    
        // Load annotations and store paths if needed
        this.loadBatchAnnotations(loadResult, [itemMetadata]);
        this.storeGirderPaths(loadResult, [itemMetadata]);
    
        // Step 5: Set primary selection for this item
        const selection = convertSuccessResultToDataSelection(loadResult[0]);
        this.datasetStore.setPrimarySelection(selection);
    
        console.log('Item loaded successfully');
      } catch (error) {
        console.error('Failed to load item:', error);
      }
    },
    async downloadCase(caseId: string, progressCallback: (progress: number) => void, skipDownloadedFiles: boolean = true) {
      try {
        const caseObj = this.cases[caseId];
        if (!caseObj) {
          console.error(`Case with ID ${caseId} not found.`);
          return;
        }

        const { input_items, output_items } = caseObj.meta;
        const allItems = [...input_items, ...output_items];

        // Filter out files that are already downloaded if skipDownloadedFiles is true
        const itemsToDownload = allItems.filter(itemId => {
          if (!skipDownloadedFiles) return true;
          // Check if the file path already exists in girderPaths
          return this.girderPaths.findIndex(entry => entry.path === itemId) === -1;
        });

        if (itemsToDownload.length === 0) {
          progressCallback(100); // No files to download, mark progress as complete
          return;
        }

        // Step 1: Fetch metadata for each item by ID
        const metadataResponses = await Promise.all(
          itemsToDownload.map(itemId => this.rest.get(`/item/${itemId}`))
        );
        const metadata = metadataResponses.map(response => response.data);

        const numberOfItems = itemsToDownload.length;
        let completedItems = 0;

        const downloadWithProgress = async (itemId: string) => {
          completedItems += 1; // Mark this item as fully downloaded after `downloadItem` completes
          const overallProgress = (completedItems / numberOfItems) * 50;
          progressCallback(overallProgress); // Update progress for completed item
          return this.downloadItem(itemId);
        };
      
        // Start all downloads and wait for them to complete
        const downloadedFiles = await Promise.all(
          itemsToDownload.map(itemId => downloadWithProgress(itemId))
        );

        // Step 3: Handle the downloaded files by converting them into data sources
        const datasources = await Promise.all(downloadedFiles.map(file => fileToDataSource(file)));

        progressCallback(80);

        try {
          const loadResults = await importDataSources(datasources);

          if (!loadResults || loadResults.length === 0) {
            throw new Error('Did not receive a load result');
          }

          progressCallback(85);

          this.loadBatchAnnotations(loadResults, metadata);
          this.storeGirderPaths(loadResults, metadata);

          if (!loadResults[0].ok) {
            throw new Error('Result not ok');
          }

          progressCallback(90);

          // Step 4: Set primary selection based on the load results
          const imageResult = loadResults.find(
            result =>
              result.data &&
              result.data.length > 0 &&
              (result.data[0].dataType === 'dicom' || result.data[0].dataType === 'image')
          );

          if (imageResult) {
            const selection = convertSuccessResultToDataSelection(imageResult);
            this.datasetStore.setPrimarySelection(selection);
          }

          progressCallback(100);

        } catch (error) {
          console.warn('Error loading download:', error);
          return;
        }

      } catch (error) {
        console.error('Download failed:', error);
      }
      progressCallback(0); // Reset download progress
    },

    resetPaths() {
      this.girderPaths = [];
    },

    async downloadItem(itemId: string) {
      try {
        const config = {
          params: {
            format: 'zip',
            'content-disposition' : 'attachment',
          },
          responseType: 'blob',
          headers: { 'Content-Type': 'application/zip' }
        };

        const { data } = await this.rest.get(`/item/${itemId}/download`, config);
        return new File([data], `${itemId}.zip`);
      } catch (error) {
        console.error(`Download failed for item ${itemId}:`, error);
        throw error;
      }
    },

    async loadCohort(cohortId: string, progressCallback: (progress: number) => void = () => {}) {
      try {
        const cohortObj = this.cohorts[cohortId];
        if (!cohortObj) {
          console.error(`Cohort with ID ${cohortId} not found.`);
          return;
        }
    
        const output_items = cohortObj.items;

        if (!output_items || output_items.length === 0) {
          console.warn('No output items found for the cohort.');
          progressCallback(100); // Complete the progress
          return;
        }
    
        const numberOfItems = output_items.length;
        let completedItems = 0;
    
        const downloadWithProgress = async (itemId: string) => {
          completedItems += 1; // Increment the completed item count
          const overallProgress = (completedItems / numberOfItems) * 50;
          progressCallback(overallProgress); // Update progress
          return this.downloadItem(itemId);
        };
    
        // Step 1: Fetch metadata for each item by ID
        const metadataResponses = await Promise.all(
          output_items.map((itemId) => this.rest.get(`/item/${itemId}`))
        );
        const metadata = metadataResponses.map((response) => response.data);
    
        // Step 2: Download all items and handle progress
        const downloadedFiles = await Promise.all(
          output_items.map((itemId) => downloadWithProgress(itemId))
        );
    
        // Step 3: Convert downloaded files into data sources
        const datasources = await Promise.all(
          downloadedFiles.map((file) => fileToDataSource(file))
        );
    
        progressCallback(80);
    
        // Step 4: Import data sources and load annotations
        const loadResults = await importDataSources(datasources);
    
        if (!loadResults || loadResults.length === 0) {
          throw new Error('No data sources loaded.');
        }
    
        progressCallback(85);
    
        this.loadBatchAnnotations(loadResults, metadata);
        this.storeGirderPaths(loadResults, metadata);
    
        if (!loadResults[0].ok) {
          throw new Error('One or more results failed to load.');
        }
    
        progressCallback(90);
    
        // Step 5: Set the cohort as the current project
        this.loadedCase = {
          ...cohortObj,
          type: ProjectType.Cohort,
        };
    
        progressCallback(100);
        console.log('Cohort loaded successfully');
      } catch (error) {
        console.error('Failed to load cohort:', error);
      }
      progressCallback(0); // Reset the progress
    },

    async createCohort(cohortInfo: { name: string; anatomy: string; isVirtual: boolean; samples?: number }) {
      if (!this.cohortsFolder) {
        console.warn('No cohort folder selected.');
        return { success: false, message: 'No cohort folder selected.' };
      }
    
      const { name, anatomy, isVirtual, samples } = cohortInfo;
      const parentFolderId = this.cohortsFolder._id; // The ID of the folder where cohorts are stored
    
      try {
        // Create a new folder for the cohort
        const folderConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          params: {
            parentId: parentFolderId,
            name: name,
            description: `Cohort for ${anatomy} - ${isVirtual ? 'Virtual' : 'Real'}`,
          },
        };
        const { data: folderData } = await this.rest.post('/folder', null, folderConfig);
    
        // Add metadata to the cohort folder
        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };
        const metadata = {
          anatomy: anatomy,
          type: isVirtual ? 'virtual' : 'real',
          state: 'state_empty',
          samples: isVirtual ? String(samples) : '0',
          prepared: 'false',
          input_items: '[]',
          output_items: '[]',
        };
        await this.rest.put(`/folder/${folderData._id}/metadata`, metadata, metadataConfig);
    
        // Return the created cohort data
        return {
          success: true,
          data: {
            ...folderData,
            id : folderData._id,
            meta: metadata,
            type: ProjectType.Cohort,
          },
        };
      } catch (error: any) {
        if (error.response && error.response.status === 400 && error.response.data.message.includes('A folder with that name already exists')) {
          return { success: false, message: 'A folder with that name already exists.' };
        }
        console.error('Error creating cohort:', error);
        return { success: false, message: 'An unexpected error occurred. Please try again.' };
      }
    },

    async createCase(caseInfo: { name: string; anatomy: string; anatomicalSide: string; task: string }) {

      if(!this.caseFolder){
        console.warn('No case folder selected.');
        return; 
      }

      const { name, anatomy, anatomicalSide, task } = caseInfo;
      const parentFolderId = this.caseFolder._id; // The ID of the folder where cases are stored

      try {
        // Create a new folder
        const folderConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          params: {
            parentId: parentFolderId,
            name: name,
            description: `Case for ${anatomy}`,
          },
        };
        const { data: folderData } = await this.rest.post('/folder', null, folderConfig);

        // Add metadata to the folder
        const metadataConfig = {
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        };
        const metadata = {
          anatomy: anatomy,
          task: task,
          state: 'state_empty',
          input_items: '[]',
          output_items: '[]',
        };
        await this.rest.put(`/folder/${folderData._id}/metadata`, metadata, metadataConfig);

        // Return the created case data
        return {
          ...folderData,
          meta: metadata,
          type: ProjectType.SingleCase,
        };
      } catch (error) {
        console.error('Case creation failed:', error);
        throw error;
      }
    },
  },
});
