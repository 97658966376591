<script setup lang="ts">
import { computed } from 'vue';
import { useToolStore } from '../store/tools';
import { Tools } from '../store/tools/types';
import RectangleControls from './RectangleControls.vue';
import RulerControls from './RulerControls.vue';
import SplineControls from './SplineControls.vue';

const toolStore = useToolStore();

const tools = new Map([
  [Tools.Rectangle, { component: RectangleControls, label: 'Rectangle' }],
  [Tools.Ruler, { component: RulerControls, label: 'Ruler' }],
  [Tools.Spline, { component: SplineControls, label: 'Surface Measurement Controls' }],
]);

const tool = computed(() => tools.get(toolStore.currentTool));
</script>

<template>
  <div v-if="tool">
    <div class="header">{{ tool.label }}</div>
    <div class="content">
      <component :is="tool.component" />
    </div>
  </div>
</template>

<style scoped src="./styles/annotations.css"></style>
