<template svg>
<svg
   width="80mm"
   height="9.1360226mm"
   viewBox="0 0 80.0 9.1360226"
   version="1.1"
   id="svg1"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg">
  <defs
     id="defs1" />
  <g
     inkscape:label="Ebene 1"
     inkscape:groupmode="layer"
     id="layer1"
     transform="translate(-73.585703,-107.96141)">
    <g
       id="g2"
       inkscape:export-filename="g1.svg"
       inkscape:export-xdpi="12.829076"
       inkscape:export-ydpi="12.829076"
       transform="matrix(0.14579496,0,0,0.14579496,70.485453,105.34317)">
      <path
         inkscape:export-ydpi="135.27547"
         inkscape:export-xdpi="135.27547"
         inkscape:connector-curvature="0"
         id="path4165"
         d="m 57.205458,18.778509 c -0.772134,0.09194 -1.570514,0.275008 -2.387891,0.549381 -4.365467,1.465607 -8.851133,5.445628 -12.697434,11.018308 -9.463219,-0.209841 -17.107058,2.932801 -19.414992,8.822928 -2.388632,6.096106 1.560539,13.540952 9.366858,19.399515 -0.322712,4.004971 -0.120465,7.766208 0.644605,11.015662 1.914842,8.133292 7.127107,11.870346 13.66594,9.675125 4.39846,-1.47664 8.922121,-5.506032 12.787683,-11.144912 9.757039,0.368406 17.689962,-2.782702 20.049675,-8.80483 C 81.67722,53.038267 77.429599,45.339474 69.172694,39.408892 69.427805,35.633632 69.21008,32.091655 68.484301,29.008597 66.808854,21.891994 62.610339,18.134858 57.205458,18.778509 Z"
         style="fill:#000000;fill-opacity:1;stroke:#ffffff;stroke-width:1.50136;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <path
         inkscape:export-ydpi="135.27547"
         inkscape:export-xdpi="135.27547"
         inkscape:connector-curvature="0"
         id="path4167"
         d="m 42.463218,30.408561 c -1.915715,2.775135 -3.678343,5.941456 -5.182949,9.394348 -2.718276,6.23443 -4.380071,12.809141 -4.865185,18.828173 3.505121,2.630435 7.783354,4.94591 12.619276,6.66967 4.966732,1.770327 9.9187,2.704941 14.473369,2.876947 1.880685,-2.743676 3.606218,-5.8683 5.087171,-9.266899 2.811198,-6.447525 4.49744,-13.254355 4.91527,-19.438726 -3.372802,-2.42226 -7.412302,-4.549616 -11.938106,-6.162833 -5.198718,-1.853063 -10.381059,-2.795456 -15.108846,-2.90068 z"
         style="fill:#ffffff;fill-opacity:1;stroke:#ffffff;stroke-width:1.50136;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <circle
         inkscape:export-ydpi="135.27547"
         inkscape:export-xdpi="135.27547"
         style="fill:#df1515;fill-opacity:1;stroke:none;stroke-width:0.314917"
         id="path4169"
         cx="46.275639"
         cy="38.300385"
         r="3.8173761" />
    </g>
    <text
       xml:space="preserve"
       style="font-size:5.52266px;font-family:'Berlin Sans FB';-inkscape-font-specification:'Berlin Sans FB';fill:#b3b3b3;stroke-width:6.65326"
       x="85.857124"
       y="114.47693"
       id="text1"
       inkscape:export-xdpi="12.829076"
       inkscape:export-ydpi="12.829076"><tspan
         sodipodi:role="line"
         id="tspan1"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:Arial;-inkscape-font-specification:Arial;fill:#b3b3b3;stroke-width:6.65326"
         x="85.857124"
         y="114.47693">Shapemeans Insight</tspan></text>
  </g>
</svg>


</template>

<script>
export default {
  name: 'volview-full-logo',
};
</script>
