import { ref } from 'vue';

const uploadProgress = ref(0);

export function progressCallback(progress: number) {
  console.log('progress', progress);
  uploadProgress.value = progress;
}

export function resetProgress() {
  uploadProgress.value = 0;
}

export { uploadProgress };