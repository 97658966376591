export enum Tools {
  WindowLevel = 'WindowLevel',
  Pan = 'Pan',
  Zoom = 'Zoom',
  Ruler = 'Ruler',
  Paint = 'Paint',
  Rectangle = 'Rectangle',
  Crosshairs = 'Crosshairs',
  Crop = 'Crop',
  Landmark3D = 'Landmark3D',
  Spline = 'Spline',
  SurfaceDrawing = 'SurfaceDrawing',
}

export enum ProjectType {
  SingleCase = 'Case',
  Reference = 'Reference',
  Cohort = 'Cohort',
}