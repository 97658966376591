<template>
  <v-card>
    <v-card-title class="d-flex flex-row align-center">Login</v-card-title>
    <v-card-text>
      <p>Login with your access key, the access key that you received from Shapemeans.</p>
      <br />
      <p>Note: You can also change your key in the settings.</p>
      <br />
      <v-text-field
        v-model="girderStore.key"
        :type="showKey ? 'text' : 'password'"
        label="Access Key"
        clearable
        append-icon="mdi-eye"
        @click:append="toggleShowKey"
      />
      <div v-if="keyValid" class="key-validity">
        <v-icon color="green">mdi-check-circle</v-icon>
        Key is valid
      </div>
      <div v-else class="key-validity">
        <v-icon color="orange">mdi-alert-circle</v-icon>
        Not Connected
      </div>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import { useGirderStore } from '../store/girder-store';

export default defineComponent({
  setup(_, { emit }) {
    const girderStore = useGirderStore();
    const showKey = ref(false); // Tracks visibility of the key

    const toggleShowKey = () => {
      showKey.value = !showKey.value;
    };

    const connectionIcon = computed(() => {
      return girderStore.connected ? 'mdi-check-circle' : 'mdi-alert-circle';
    });
    const connectionIconColor = computed(() => {
      return girderStore.connected ? 'green' : 'orange';
    });

    const login = () => {
      girderStore.initializeAxios();
      girderStore.login(girderStore.key);
    };

    watch(
      () => girderStore.key,
      (newKey) => {
        if (newKey) {
          login();
        }
      }
    );

    const keyValid = computed(() => girderStore.connected);

    return {
      connectionIcon,
      connectionIconColor,
      girderStore,
      login,
      keyValid,
      showKey,
      toggleShowKey
    };
  }
});
</script>