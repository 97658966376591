<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useSplineStore } from '@/src/store/tools/splines';

export default defineComponent({
  setup() {
    const splineStore = useSplineStore();
    const editingId = ref<number | null>(null);
    const newName = ref('');

    const splines = computed(() => {
      return splineStore.splines;
    });

    function remove(id: number) {
      splineStore.removeSpline(id);
    }

    function hover(id: number | null) {
      splineStore.updateHoveredSpline(id); // Store the hovered spline
    }

    function startEditing(id: number, currentName: string) {
      editingId.value = id;
      newName.value = currentName;
    }

    function saveName(id: number) {
      if (newName.value.trim()) {
        splineStore.updateSplineName(id, newName.value);
      }
      editingId.value = null;
    }

    function cancelEditing() {
      editingId.value = null;
    }

    return {
      splines,
      remove,
      hover,
      editingId,
      newName,
      startEditing,
      saveName,
      cancelEditing,
    };
  },
});
</script>

<template>
    <v-list-item
      v-for="spline in splines"
      :key="spline.id"
      lines="two"
      @mouseenter="hover(spline.id)"
      @mouseleave="hover(null)"
    >
      <template #prepend>
        <v-icon class="tool-icon">mdi-vector-line</v-icon>
      </template>
      <v-list-item-title v-bind="$attrs">
        <template v-if="editingId === spline.id">
          <v-text-field
            v-model="newName"
            dense
            @blur="saveName(spline.id)"
            @keydown.enter="saveName(spline.id)"
            @keydown.esc="cancelEditing"
          />
        </template>
        <template v-else>
          <span @click="startEditing(spline.id, spline.name)">{{ spline.name || 'Unnamed (Edit)' }}</span>
          <v-chip v-if="!spline.name" label small color="success" text-color="white">New</v-chip>
        </template>
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-row>
          <v-col>Length: {{ spline.length }}</v-col>
        </v-row>
      </v-list-item-subtitle>
      <template #append>
        <v-row>
          <v-btn icon="mdi-delete" variant="text" @click="remove(spline.id)">
            <v-icon>mdi-delete</v-icon>
            <v-tooltip location="top" activator="parent">Delete</v-tooltip>
          </v-btn>
        </v-row>
      </template>
    </v-list-item>
  </template>

<style src="@/src/components/styles/utils.css"></style>

<style scoped>
.empty-state {
  text-align: center;
}

.color-dot {
  width: 24px;
  height: 24px;
  background: yellow;
  border-radius: 16px;
}

.tool-icon {
  margin-inline-end: 12px;
}
</style>