<template>
  <div>
    <h3 class="mb-4">Girder</h3>
    <v-text-field
      v-model="girderStore.host"
      class="server-param"
      label="Host Address"
      clearable
    />
    <v-text-field
      v-model="girderStore.port"
      class="server-param"
      label="Port (Leave empty for default)"
      clearable
    />
    <!-- API Key Field with Show/Hide Toggle -->
    <v-text-field
      v-model="girderStore.key"
      :type="showKey ? 'text' : 'password'"
      class="server-param"
      label="API Key"
      clearable
      append-icon="mdi-eye"
      @click:append="toggleShowKey"
    />
    <!-- Connection Status -->
    <div class="connection-status">
      <v-icon :color="connectionIconColor">{{ connectionIcon }}</v-icon>
      Connected
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, computed } from 'vue';
import { useGirderStore } from '../store/girder-store';

export default defineComponent({
  setup() {
    const girderStore = useGirderStore();

    // Host change detection for re-fetching metadata
    const hostAtStart = ref(girderStore.host);
    onMounted(() => {
      hostAtStart.value = girderStore.host;
    });
    onUnmounted(() => {
      if (hostAtStart.value !== girderStore.host) {
        girderStore.initializeAxios();
        girderStore.login(girderStore.key);
      }
    });

    // API key visibility toggle
    const showKey = ref(false);
    const toggleShowKey = () => {
      showKey.value = !showKey.value;
    };

    // Compute connection icon and color
    const connectionIcon = computed(() => {
      return girderStore.connected ? 'mdi-check-circle' : 'mdi-alert-circle';
    });
    const connectionIconColor = computed(() => {
      return girderStore.connected ? 'green' : 'orange';
    });

    return {
      connectionIcon,
      connectionIconColor,
      girderStore,
      showKey,
      toggleShowKey,
    };
  },
});
</script>