import macro from '@kitware/vtk.js/macro';

import vtkSlicedGeometryRepresentationProxy from '@kitware/vtk.js/Proxy/Representations/SlicedGeometryRepresentationProxy';

const SLICE_MODE_MAP = {
  X: 'X',
  Y: 'Y',
  Z: 'Z',
  I: 'X',
  J: 'Y',
  K: 'Z',
};

function vtkGeometryIJKSliceRepresentationProxy(publicAPI, model) {
  model.classHierarchy.push('vtkGeometryIJKSliceRepresentationProxy');

  const superClass = { ...publicAPI };

  // pretend IJK slicing is actually just XYZ.
  publicAPI.setSlicingMode = (mode) => {

    return superClass.setSlicingMode(SLICE_MODE_MAP[mode]);
    
  };

}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Object methods
  vtkSlicedGeometryRepresentationProxy.extend(publicAPI, model);

  // Object specific methods
  vtkGeometryIJKSliceRepresentationProxy(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(
  extend,
  'vtkGeometryIJKSliceRepresentationProxy'
);

// ----------------------------------------------------------------------------

export default { newInstance, extend };
