<template>
  <div class="d-flex flex-column" height="100%" style="overflow: hidden;">
    <!-- Buttons to open New Case Dialog and Load Case Dialog -->
    <v-row class="mt-4 pa-4" align="center">
      <v-col cols="4">
        <v-btn color="primary" @click="dialogNewCase = true" block>
          <v-icon left>mdi-plus-box</v-icon>
          Case
        </v-btn>
      </v-col>

      <v-col cols="4">
        <v-btn color="primary" @click="dialogLoadCase = true" block>
          <v-icon left>mdi-folder-open</v-icon>
          Cases
        </v-btn>
      </v-col>

      <v-col cols="4">
        <v-btn color="primary" @click="dialogReferences = true" block>
          References
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="pa-4" align="center">
      <v-col cols="4">
        <v-btn color="primary" @click="showCohortCreator = true" block>
          <v-icon left>mdi-plus-box</v-icon>
          Cohort
        </v-btn>
      </v-col>
      <v-col cols="4">
    <v-btn color="primary" @click="dialogCohortBrowser = true" block>
      <v-icon left>mdi-folder-multiple</v-icon>
      Cohorts
    </v-btn>
    <CohortBrowser
  :visible="dialogCohortBrowser"
  @close="dialogCohortBrowser = false"
/>
  </v-col>
    </v-row>

    <v-dialog v-model="showCohortCreator" max-width="600px" persistent>
      <v-card>

        <CohortCreator @close="showCohortCreator = false" />

      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogReferences" scrollable max-width="60%">
      <v-card>
        <v-card-title>
          <span class="headline">References</span>
        </v-card-title>

        <v-card-text class="scrollable-content">
          <!-- References Listing Table -->
          <v-data-table :headers="header_reference_list" :items="referencesList" hide-default-footer>
            <template v-slot:item.anatomy="{ item }">
              {{ item.anatomy }}
            </template>
            <template v-slot:item.id="{ item }">
              <!-- Load button that calls loadReference with the item's id -->
              <v-btn color="primary" small @click="loadReference(item.id)">
                Load
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogReferences = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New Case Dialog -->
    <v-dialog v-model="dialogNewCase" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Create New Case</span>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-text-field label="Case Name" v-model="newCaseName" required></v-text-field>
            <v-select label="Anatomy" :items="anatomies" v-model="selectedAnatomy" item-title="text" item-value="value"
              required></v-select>
            <v-select label="Task on upload" :items="tasks" v-model="selectedTask" required></v-select>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="createCase">
            <v-icon left>mdi-plus-box</v-icon>
            Create Case
          </v-btn>
          <v-btn @click="dialogNewCase = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Load Case Dialog -->
    <v-dialog scrollable v-model="dialogLoadCase" height="80%" max-width="60%">
      <v-card>
        <!-- Fixed header for the dialog -->
        <v-card-title>
          <span class="headline">Open Case</span>
          <v-row class="mt-2">
            <v-col cols="12" sm="4">
              <v-select label="Anatomy" :items="anatomies" v-model="anatomyFilter" item-value="value" item-title="text"
                clearable></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select label="Task" :items="tasks" v-model="taskFilter" item-value="task" item-title="task"
                clearable></v-select>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select label="State" :items="states" v-model="stateFilter" item-value="state" item-title="state"
                clearable></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="4">
              <v-text-field v-model="searchQuery" label="Search" clearable></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- Case list with search and filters -->
        <v-card-text class="scrollable-content">
          <v-data-table :items="filteredCasesList" loading-text="Loading cases..." item-key="caseId" hide-default>
            <template v-slot:item.state="{ item }">
              <v-progress-circular :model-value="getStateProgress(item.state)" color="blue-grey"></v-progress-circular>
            </template>

            <template v-slot:item.task="{ item }">
              <v-chip v-if="item.task" small color="blue" class="mr-4">
                {{ item.task }}
              </v-chip>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn v-if="item.state !== 'state_empty'" @click="downloadCase(item.action)">
                <v-icon left>mdi-download</v-icon>
                Download
              </v-btn>

              <v-btn v-else @click="uploadData(item.id, item.task)" :disabled="!validFileLoaded">
                <v-icon left :color="!validFileLoaded ? 'default' : 'grey'">mdi-upload</v-icon>
                Upload
              </v-btn>
            </template>

          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogLoadCase = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-divider class="my-4"></v-divider>

    <v-card v-if="loadedCase" class="pa-4 mb-4">
      <template v-if="loadedCase.type === 'Case'">
        <v-card-title class="pl-4" style="white-space: pre-wrap; word-break: break-word;">
          {{ loadedCase.name }}
        </v-card-title>
        <v-card-subtitle class="pl-4">
          Task: {{ loadedCase.meta?.task || 'No task selected' }}
        </v-card-subtitle>
        <v-btn v-if="!isJobFinished(loadedCase.state)" @click="uploadData(loadedCase._id, loadedCase.meta?.task)"
          :disabled="!validFileLoaded" class="ml-4 mt-2">
          <v-icon left :color="!validFileLoaded ? 'grey' : 'default'">mdi-upload</v-icon>
          Upload & Submit
        </v-btn>
        <div v-if="jobIsAvailable" class="mt-4">
          <p class="pl-4"><strong>Job ID:</strong> {{ jobDetails.jobId }}</p>
          <p class="pl-4"><strong>Current progress:</strong> {{ jobDetails.progress.message }}</p>
          <div class="mb-4"></div>
          <v-card class="rounded-lg pa-4 mb-4" elevation="1" outlined style="background-color: #2c2c2c; color: white;">
            <v-card-text>
              <pre style="max-height: 200px; overflow-y: auto; white-space: pre-wrap; color: white;">
            <code>
              {{ jobLog }}
            </code>
          </pre>
            </v-card-text>
          </v-card>
        </div>
      </template>
      <template v-if="loadedCase.type === 'Reference'">
        <v-card-title class="pl-4" style="white-space: pre-wrap; word-break: break-word;">
          Reference Annotation Template
        </v-card-title>
        <v-card-subtitle class="pl-4">
          {{ loadedCase.name }}
        </v-card-subtitle>
        <div class="pl-4 mt-2" style="font-size: 14px; line-height: 1.6;">
          These template measurements can then be applied to existing and new cases.
          Add new annotations in Annotations and store them by pressing 'Upload'.
        </div>
        <div class="d-flex align-center ml-4 mt-4">
          <v-btn @click="uploadReferenceAnnotations(loadedCase.id)" class="mr-2">
            <v-icon left>mdi-upload</v-icon>
            Upload New Annotations
          </v-btn>
          <v-icon v-if="refreshSuccess" color="green" small>mdi-check-circle</v-icon>
        </div>
      </template>
      <template v-if="loadedCase.type === 'Cohort'">
        <v-card-title class="pl-4" style="white-space: pre-wrap; word-break: break-word;">
          Cohort Information
        </v-card-title>
        <v-card-subtitle class="pl-4">
          Name: {{ loadedCase.name }}
        </v-card-subtitle>
        <div class="pl-4 mt-2" style="font-size: 14px; line-height: 1.6;">
          Cohorts are a collection of examples in correspondence with a reference template of a specific anatomy.
          You can create cohorts above and a worker either build the virtual samples or collects the requested cases.
        </div>
      </template>
    </v-card>

    <v-spacer></v-spacer>

    <v-toolbar class="justify-center mt-auto">
      <v-spacer />
      <v-icon v-if="isServerConnected" color="green">mdi-check-circle</v-icon>
      <span v-if="isServerConnected" class="ml-2">Server connected</span>
      <v-spacer />
    </v-toolbar>
  </div>
</template>

<script lang="ts">
import { computed, ref, onMounted, watch, onBeforeUnmount } from 'vue';
import { useGirderStore } from '../store/girder-store';
import { useDatasetStore } from '../store/datasets';
import { useFileStore } from '../store/datasets-files';
import { useModelStore } from '../store/datasets-models';
import { useSurfaceMeasurementStore } from '../store/tools/surfaceMeasurements'
import { useLandmarkStore } from '../store/tools/landmarks';
import { useSurface3DAnnotationStore } from '../store/tools/surfaceDrawings';
import { useMessageStore } from '../store/messages';
import { progressCallback, uploadProgress, resetProgress } from '../store/progress-store';
import GirderLogin from './GirderLogin.vue';
import CohortCreator from './CohortCreator.vue';
import CohortBrowser from './CohortBrowser.vue';

export default {
  name: 'GirderBrowser',
  components: {
    CohortCreator,
    CohortBrowser,
  },

  setup() {
    const newProjectName = ref('');
    const selectedAnatomy = ref('');
    const selectedTask = ref('');
    const loadedCase = computed(() => girder.loadedCase)
    const dialogNewCase = ref(false);
    const searchQuery = ref('');
    const dialogLoadCase = ref(false);
    const showCohortCreator = ref(false);
    const girder = useGirderStore();
    const fileStore = useFileStore();
    const taskFilter = ref('');
    const stateFilter = ref('');
    const anatomyFilter = ref('');
    const datasetStore = useDatasetStore();
    const expandedCase = ref([]);
    const dialogCohortBrowser = ref(false);
    const jobDetails = ref({});
    const refreshSuccess = ref(false);
    const loadedJobDetails = ref({});
    const surfaceStore = useSurfaceMeasurementStore();
    const states = ref(['state_empty', 'state_image_uploaded', 'state_image_processed']);
    const tasks = ref(['Auto measurements', 'Prepare for simulation', 'Reconstruct', 'Fit device', 'Registration', 'Foot Fitting']);
    const header_reference_list = ref([{ title: 'Anatomy', sortable: true, value: 'anatomy' }, { title: 'Type', sortable: true, value: 'type' }, { title: 'Action', sortable: true, value: 'id' }]);
    const currentJobState = ref<number | null>(null);
    const dialogReferences = ref(false);

    // Mock jobDetails when not available
    const defaultJobDetails = {
      jobId: 'N/A',
      progress: { message: 'No progress available' },
      log: [],
    };

    // Function to refresh job details and log
    const refreshJobDetails = async () => {
      if (loadedCase.value?.type === 'Reference') {
        return;
      }
      if (!loadedCase.value?.meta.jobId) {
        jobDetails.value = { log: [], progress: { message: 'No Job Available' }, jobId: 'N/A', status: '', };
        return;
      }
      if (loadedCase.value && loadedCase.value.meta.jobId) {
        try {
          const job = await girder.getJob(loadedCase.value.meta.jobId);
          if (job && job.jobId) {
            jobDetails.value = job;
          } else {
            jobDetails.value = { log: [], progress: { message: 'No progress available' }, jobId: 'N/A', status: '', };
          }
        } catch (error) {
          console.error('Error fetching job details:', error);
          jobDetails.value = { log: [], progress: { message: 'No progress available' }, jobId: 'N/A', status: '' };
        }
      }
    };

    // Function to check if the job state is finished
    const isJobFinished = (state: string) => {
      return state === 'state_image_processed'; // Assuming this is the finished state
    };

    // Watch on loadedCase to update the current case and refresh log
    watch(loadedCase, async (newCase) => {
      if (newCase) {
        await refreshJobDetails();
      }
    });

    const refreshCaseFiles = async () => {
      const newJobState = jobDetails.value.status; // Assuming `status` holds the job state

      if (currentJobState.value && loadedCase.value) {
        // If the job state is finished (3), trigger the download of case files
        if (newJobState === 3 && currentJobState.value !== 3) {
          useMessageStore().addSuccess('Job completed. Downloading updated case data...');
          await girder.downloadCase(loadedCase.value?.id, progressCallback, true);
        }
      }

      // Update the current job state after checking
      currentJobState.value = newJobState;

    };

    // Computed job details to avoid null values
    const computedJobDetails = computed(() => {
      return jobDetails.value && Object.keys(jobDetails.value).length
        ? jobDetails.value
        : defaultJobDetails;
    });

    // Computed job log to avoid null
    const jobLog = computed(() => {
      return computedJobDetails.value.log.length
        ? computedJobDetails.value.log.join('\n')
        : 'No log available';
    });

    // Computed flag to check if job details are available
    const jobIsAvailable = computed(() => {
      return computedJobDetails.value.jobId !== 'N/A';
    });

    let refreshInterval: any = null;

    // Initialize login and list cases when the component is mounted
    onMounted(async () => {

      await girder.initializeAxios();
      await girder.login(girder.key);
      await girder.listProjects();
      await girder.fetchAnatomies();  // Fetch anatomies after login

      // Set an interval to refresh the cases list every 60 seconds (60000 ms)
      refreshInterval = setInterval(async () => {
        await girder.listProjects();
        if (loadedCase.value) {
          await refreshJobDetails();
          await refreshCaseFiles();
        }
        if (refreshSuccess.value) {
          refreshSuccess.value = false;
        }
      }, 10000);
    });

    // Clean up the interval when the component is unmounted
    onBeforeUnmount(() => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    });

    // Computed property to check if the server is connected
    const isServerConnected = computed(() => girder.connected);

    watch(
      () => [girder.host, girder.port, girder.key],
      () => {
        girder.initializeAxios();
        girder.login(girder.key);
      },
      { deep: true }
    );

    const selectedImageID = computed(() => {
      const { primarySelection } = useDatasetStore();
      if (primarySelection?.type === 'image') {
        return primarySelection.dataID;
      }
      if (primarySelection?.type === 'dicom') {
        return primarySelection.volumeKey || null;
      }
      return null;
    });

    const selectedMeshID = computed(() => {
      const { currentId } = useModelStore();
      return currentId;
    });

    const isImageLoaded = computed(() => selectedImageID.value !== null);

    const isModelLoaded = computed(() => selectedMeshID.value !== null);

    const validFileLoaded = computed(() => isImageLoaded.value || isModelLoaded.value);

    const updateData = async (d: string) => {

      progressCallback(0);

      const caseId = loadedCase.value.id;

      // Get access to modelStore
      const modelStore = useModelStore();

      // Iterate over all models in modelStore
      for (const id of modelStore.idList) {
        const file = fileStore.getFiles(id);

        if (!file) {
          console.warn(`File not found for model: ${id}`);
          continue;
        }

        // Check if the model ID exists in girder.girderPaths by finding an object with a matching id
        const modelInGirder = girder.girderPaths.find((pathObj) => pathObj.id === id);

        try {
          if (modelInGirder) {
            // Model is already present, only upload metadata (landmarks and surface labels)
            const lmStore = useLandmarkStore();
            const lms = lmStore.serializeJSON(id);

            const paintStore = useSurface3DAnnotationStore();
            const surfaceLabels = paintStore.serializeJSON(id);

            // Update the metadata with landmarks and surface labels
            await girder.updateMetadata(modelInGirder.path, {
              'landmarks': lms,
              'surfaceLabels': surfaceLabels,
            });
          } else {
            // Model not present, upload the model and metadata
            const modelUploadResult = await girder.uploadFile(file, caseId, 'Update', progressCallback);

            // Resubmit the annotations (landmarks and surface labels)
            const lmStore = useLandmarkStore();
            const lms = lmStore.serializeJSON(id);

            const paintStore = useSurface3DAnnotationStore();
            const surfaceLabels = paintStore.serializeJSON(id);

            if (modelUploadResult.length > 0) {
              // Update the metadata with landmarks and surface labels
              await girder.updateMetadata(modelUploadResult[0], {
                'landmarks': lms,
                'surfaceLabels': surfaceLabels,
              });
            }
          }

          progressCallback(30);

          // Create a new job based on the current job with a descriptive task
          const currentTask = loadedCase.value.meta.jobId || "defaultJobID";
          const jobDescription = `${currentTask}-Update`;
          await girder.createJobWithInputItems([], jobDescription, loadedCase.value.meta.task, caseId);

        } catch (error) {
          console.error(`Error updating or uploading model ${id}:`, error);
        }

        progressCallback(80);

        await girder.listCases();
        loadedCase.value = girder.cases[caseId];

        progressCallback(100);

        progressCallback(0);

      }
    };
    const uploadData = async (caseId: string, task: string) => {
      const allUploadedItems = []; // Array to hold uploaded item IDs
      const allFiles = []; // Array to hold the formatted objects for storeGirderPaths

      try {
        if (isImageLoaded.value) {
          const id = selectedImageID.value;
          const file = fileStore.getFiles(id);

          // Capture and log the result of the upload
          const itemIDs = await girder.uploadFile(file, caseId, task, progressCallback);

          // Create the allFiles entry for the image
          allFiles.push({
            _id: id, // Assuming _id exists in file metadata
            data: [{ dataID: id, dataType: 'image' }],
            ok: true, // Assuming the upload was successful
          });

          allUploadedItems.push(itemIDs[0]); // Add the uploaded item ID to the list
        }

        if (isModelLoaded.value) {
          const id = selectedMeshID.value;
          const file = fileStore.getFiles(id);

          // Capture and log the result of the upload
          const modelUploadResult = await girder.uploadFile(file, caseId, task, progressCallback);

          // Create the allFiles entry for the model
          allFiles.push({
            _id: id, // Assuming _id exists in file metadata
            data: [{ dataID: id, dataType: 'model' }],
            ok: true, // Assuming the upload was successful
          });

          allUploadedItems.push(...modelUploadResult); // Add all uploaded item IDs to the list

          // Handle landmarks and surface labels
          const lmStore = useLandmarkStore();
          const lms = lmStore.serializeJSON(id);
          const paintStore = useSurface3DAnnotationStore();
          const surfaceLabels = paintStore.serializeJSON(id);

          if (modelUploadResult.length > 0) {
            await girder.updateMetadata(modelUploadResult[0], {
              landmarks: lms,
              surfaceLabels: surfaceLabels,
            });
          }
        }

        // Call storeGirderPaths with the properly formatted allFiles and loadResults (allUploadedItems)
        await girder.storeGirderPaths(
          allFiles.map((file) => ({ ok: true, data: file.data })), // loadResults format for storeGirderPaths
          allUploadedItems.map((item) => ({ _id: item })), // uploadResults format for storeGirderPaths
        );

        // Create a job with the uploaded input items
        await girder.createJobWithInputItems(allUploadedItems, task, task, caseId);

        // Refresh the case list and set the loaded case
        await girder.listCases();
        girder.setLoadedCaseByID(caseId);

      } catch (error) {
        console.error(`Error during upload for case ${caseId}:`, error);
      } finally {
        progressCallback(0); // Reset progress callback
      }
    };


    const anatomies = computed(() => girder.anatomies);
    const casesList = computed(() => girder.cases);
    const referencesList = computed(() => Object.values(girder.references));


    const filteredCasesList = computed(() => {
      return Object.values(casesList.value).filter((caseItem: Case) => {
        const matchesTask = taskFilter.value ? caseItem.meta.task === taskFilter.value : true;
        const matchesState = stateFilter.value ? caseItem.state === stateFilter.value : true;
        const matchesAnatomy = anatomyFilter.value ? caseItem.meta.anatomy === anatomyFilter.value : true;
        const searchQ = searchQuery.value ? searchQuery.value.toLowerCase() : '';
        const matchesSearch = caseItem.name.toLowerCase().includes(searchQ);

        return matchesTask && matchesState && matchesAnatomy && matchesSearch;
      }).map((caseItem) => ({
        name: caseItem.name,
        anatomy: caseItem.meta.anatomy,
        task: caseItem.meta.task,
        state: caseItem.state,
        action: caseItem.id,
      }));
    });

    const createCase = async () => {
      if (!newProjectName.value || !selectedAnatomy.value || !selectedTask.value) {
        alert('Please fill all required fields.');
        return;
      }
      try {
        const caseData = await girder.createCase({
          name: newProjectName.value,
          anatomy: selectedAnatomy.value,
          anatomicalSide: 'Left',
          task: selectedTask.value,
        });

        girder.setLoadedCase(caseData);

      } catch (error) {
        console.error('Failed to create case:', error);
      }
    };

    const uploadReferenceAnnotations = async (girderPath: string) => {
      try {
        console.log("Upload initiated.");

        // Access the measurement store
        const measurementStore = useSurfaceMeasurementStore();

        // Serialize the measurements
        const { measurements } = measurementStore.serializeMeasurements();

        // Create the metadata object
        const metadata = {
          measurements: measurements,
        };

        await girder.updateMetadata(girderPath, metadata);

        useMessageStore().addSuccess("Successfully stored annotations")

      } catch (error) {
        console.error("Error uploading reference annotations:", error);
      }
    };

    const downloadCase = async (caseId: string) => {
      surfaceStore.deleteAllMeasurements();
      datasetStore.deleteAllData();
      girder.resetPaths();
      girder.setLoadedCaseByID(caseId);
      refreshJobDetails();
      await girder.downloadCase(caseId, progressCallback);
    };

    const loadReference = async (_id: string) => {
      surfaceStore.deleteAllMeasurements();
      datasetStore.deleteAllData();
      girder.resetPaths();
      await girder.loadItem(_id);
      const referenceCase = referencesList.value.find(s => s.id === _id)
      if (referenceCase) {
        girder.setLoadedCase(referenceCase);
      }
    };

    return {
      newCaseName: newProjectName,
      anatomies,
      selectedAnatomy,
      selectedTask,
      isJobFinished,
      jobDetails,
      currentCase: loadedCase,
      dialogNewCase,
      dialogLoadCase,
      searchQuery,
      loadedCase,
      isImageLoaded,
      expandedCase,
      header_reference_list,
      isServerConnected,
      computedJobDetails,
      jobLog,
      dialogCohortBrowser,
      jobIsAvailable,
      uploadData,
      states,
      tasks,
      taskFilter,
      stateFilter,
      dialogReferences,
      referencesList,
      loadReference,
      showCohortCreator,
      uploadReferenceAnnotations,
      isModelLoaded,
      selectedMeshID,
      updateData,
      validFileLoaded,
      downloadCase,
      refreshSuccess,
      casesList,
      filteredCasesList,
      anatomyFilter,
      uploadProgress,
      createCase,
      listCases: girder.listCases,
      getStateProgress: (state: string) => {
        if (state === 'state_image_processed') return 100;
        if (state === 'state_image_uploaded') return 50;
        return 0;
      }
    };
  }
}
</script>

<style scoped>
#data-module {
  display: flex;
  flex-flow: column;
}

#data-panels {
  flex: 2;
  overflow-y: auto;
}

.collection-header-icon {
  flex: 0;
  margin-right: 16px;
}

/* Add styling for the fixed header */
.fixed-header {
  position: sticky;
  top: 0;
  z-index: 1;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.patient-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  /* 24px accomodates the open/close icon indicator */
  max-width: calc(100% - 24px);
}

.patient-header-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.empty-state {
  display: none;
}

.no-panels:empty+.empty-state {
  display: block;
}
</style>
