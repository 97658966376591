<script lang="ts">
import { defineComponent } from 'vue';
import MeasurementsRulerList from './MeasurementsRulerList.vue';
import MeasurementsRectangleList from './MeasurementsRectangleList.vue';
import MeasurementsLandmarkList from './MeasurementsLandmarkList.vue';
import MeasurementsLineList from './MeasurementsLineList.vue'
import MeasurementsAngleList from './MeasurementsAngleList.vue'
import MeasurementsSplineList from './MeasurementsSplineList.vue';
import MeasurementsSurfaceDrawingList from './MeasurementsSurfaceDrawingList.vue';
import LabelmapList from './LabelmapList.vue';
import ToolControls from './ToolControls.vue';

export default defineComponent({
  components: {
    MeasurementsRulerList,
    MeasurementsAngleList,
    MeasurementsRectangleList,
    MeasurementsSurfaceDrawingList,
    MeasurementsSplineList,
    MeasurementsLandmarkList,
    MeasurementsLineList,
    LabelmapList,
    ToolControls,
  },
  setup() {},
});
</script>

<template>
  <div class="overflow-y-auto mx-2 fill-height">
    <tool-controls />
    <div class="header">Surface Measurements</div>
    <div class="content">
      <measurements-spline-list />
      <measurements-angle-list />
      <measurements-line-list />
      <measurements-landmark-list />
    </div>
    <div class="text-caption text-center empty-state">No surface measurements</div>
    <div class="header">Image Measurements</div>
    <div class="content">
      <measurements-ruler-list />
      <measurements-rectangle-list />
    </div>
    <div class="text-caption text-center empty-state">No measurements</div>
    <div class="header">Image Labels</div>
    <div class="content">
      <labelmap-list />
    </div>
    <div class="text-caption text-center empty-state">No labelmaps</div>
    <div class="header">Surface Labels</div>
    <div class="content">
      <measurements-surface-drawing-list/>
    </div>
    <div class="text-caption text-center empty-state">No surface labels</div>
  </div>
</template>

<style scoped>
.annot-subheader {
  margin: 8px 0;
}

.empty-state {
  display: none;
}

.content:empty + .empty-state {
  display: block;
}
</style>

<style scoped src="./styles/annotations.css"></style>
