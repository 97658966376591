<template>
  <div class="layout-container" ref="rootContainer">
    <div class="layout-row" v-for="(row, rowIndex) in rows" :key="rowIndex">
      <div v-for="modelId in row" :key="modelId" class="layout-item">
        <model-renderer
          :model-id="modelId"
          :main-render-window="mainRenderWindow"
          :main-render-window-view="mainRenderWindowView"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import ModelRenderer from './ModelRenderer.vue';
import { useModelStore } from '../store/datasets-models';
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow';

export default defineComponent({
  name: 'ShapeLayout',
  components: {
    ModelRenderer,
  },
  setup() {

    const rootContainer = ref<HTMLElement | null>(null);
    const modelStore = useModelStore();
    const modelIds = computed(() => modelStore.visibleIds);

    const rows = computed(() => {
      const rowCount = Math.ceil(modelIds.value.length / 5);
      return Array.from({ length: rowCount }, (_, i) =>
        modelIds.value.slice(i * 5, (i + 1) * 5)
      );
    });

    // Initialize the main render window and view
    const mainRenderWindow = vtkRenderWindow.newInstance();
    const mainRenderWindowView = mainRenderWindow.newAPISpecificView();
    mainRenderWindow.addView(mainRenderWindowView);
    mainRenderWindowView.initialize();

    onMounted(() => {
      if (rootContainer.value) {
        // mainRenderWindowView.setContainer(rootContainer.value);
      }
    });

    return {
      rows,
      mainRenderWindow,
      mainRenderWindowView,
      rootContainer,
    };
  },
});
</script>

<style scoped>
.layout-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Enable vertical scrolling */
}
.layout-row {
  display: flex;
  flex-direction: row;
}
.layout-item {
  flex: 0 0 calc(100% / 5); /* This will make each item take up 1/5 of the row */
  box-sizing: border-box;
  border: 1px solid #222;
}
</style>
