<script lang="ts">
import { defineComponent, toRefs, ref, computed, watch, onMounted } from 'vue';
import { useViewStore } from '@/src/store/views';
import { useLandmarkStore } from '@/src/store/tools/landmarks';
import { manageVTKSubscription } from '@/src/composables/manageVTKSubscription';
import { useModelStore } from '@/src/store/datasets-models';
import { useSplineStore } from '@/src/store/tools/splines';
import { useLineStore } from '@/src/store/tools/lines';
import { useAngleStore } from '@/src/store/tools/angles';
import { usePickerStore } from '@/src/store/pickerStore'; // Import the PickerStore
import { useSurfaceMeasurementStore } from '@/src/store/tools/surfaceMeasurements';

export default defineComponent({
  props: {
    viewId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { viewId: viewID } = toRefs(props);
    const viewStore = useViewStore();
    const pickerStore = usePickerStore(); // Use the PickerStore
    const landmarkStore = useLandmarkStore();
    const angleStore = useAngleStore();
    const { addLandmark } = landmarkStore;
    const splineStore = useSplineStore();
    const modelStore = useModelStore();
    const lineStore = useLineStore();
    const lms = ref(landmarkStore.landmarks);
    const lmsUpdated = computed(() => landmarkStore.updated);
    const linesUpdated = computed(() => lineStore.updated);
    const splinesUpdated = computed(() => splineStore.updated);
    const anglesUpdated = computed(() => angleStore.updated);
    
    // Use PickerStore to setup the selector for the current view
    pickerStore.setupPicker(viewID.value);

    function displayLines() {
      const renderer = pickerStore.renderer;
      lineStore.display(renderer);
    }

    function displayPoints() {
      const renderer = pickerStore.renderer;
      landmarkStore.display(renderer);
    }

    function displaySplines() {
      const renderer = pickerStore.renderer;
      splineStore.display(renderer);
    }

    function displayAngles() {
      const renderer = pickerStore.renderer;
      angleStore.display(renderer);
    }

    onMounted(() => {
      manageVTKSubscription(
        pickerStore.viewProxy?.getInteractor().onRightButtonPress((callData) => {

          if (!landmarkStore.active) return;

          const pos = callData.position;

          pickerStore.selector?.selectAsync(pickerStore.renderer, pos.x, pos.y, pos.x, pos.y).then((selection) => {
            if (selection.length > 0) {
              const {
                worldPosition: pickedPoint,
                prop,
              } = selection[0].getProperties();

              const input = prop.getMapper().getInputData();

              if (selection[0].getProperties().prop) {
                const modelId = modelStore.findIndexByPolyData(input);
                if (modelId) {
                  addLandmark(pickedPoint[0], pickedPoint[1], pickedPoint[2], modelId, "");
                  displayPoints();
                }
              }
            }
          });
        })
      );
    });

    watch(() => lms.value.length, displayPoints, { deep: true });
    watch(lmsUpdated, displayPoints, { deep: true });
    watch(linesUpdated, displayLines, { deep: true });
    watch(splinesUpdated, displaySplines, { deep: true });
    watch(anglesUpdated, displayAngles, { deep: true });

    return {
      lmsUpdated,
      linesUpdated,
      displayPoints,
      displayLines,
      displayAngles,
      displaySplines,
      anglesUpdated,
      splinesUpdated,
    };
  },
});
</script>

<template>
  <div>
      <canvas ref="canvas" />
  </div>
</template>
