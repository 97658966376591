<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useLandmarkStore } from '@/src/store/tools/landmarks';

export default defineComponent({
  setup() {
    const landmarkStore = useLandmarkStore();
    const editingId = ref<number | null>(null);
    const newName = ref('');

    const landmarks = computed(() => {
      return landmarkStore.landmarks.filter(lm => lm.listed);
    });

    function remove(id: number) {
      landmarkStore.removeLandmark(id);
    }

    function hover(id: number | null) {
      landmarkStore.updateHoveredLandmark(id);
    }

    function startEditing(id: number, currentName: string) {
      editingId.value = id;
      newName.value = currentName;
    }

    function saveName(id: number) {
      if (newName.value.trim()) {
        landmarkStore.updateLandmarkName(id, newName.value);
      }
      editingId.value = null;
    }

    function cancelEditing() {
      editingId.value = null;
    }

    return {
      landmarks,
      hover,
      remove,
      editingId,
      newName,
      startEditing,
      saveName,
      cancelEditing,
    };
  },
});
</script>

<template>
  <v-list-item
    v-for="landmark in landmarks"
    :key="landmark.id"
    lines="two"
    @mouseenter="hover(landmark.id)"
    @mouseleave="hover(null)"
  >
    <template #prepend>
      <v-icon class="tool-icon">mdi-map-marker</v-icon>
      <div>{{ landmark.id }}</div>
    </template>
    <v-list-item-title v-bind="$attrs">
      <template v-if="editingId === landmark.id">
        <v-text-field v-model="newName" dense @blur="saveName(landmark.id)" @keydown.enter="saveName(landmark.id)" @keydown.esc="cancelEditing" />
      </template>
      <template v-else>
        <span @click="startEditing(landmark.id, landmark.name)">{{ landmark.name }}</span>
      </template>
    </v-list-item-title>
    <v-list-item-subtitle>
      <v-row>
        <v-col>Belongs to mesh: {{ landmark.parentMeshId }}</v-col>
      </v-row>
    </v-list-item-subtitle>
    <template #append>
      <v-row>
        <v-btn icon="mdi-delete" variant="text" @click="remove(landmark.id)">
          <v-icon>mdi-delete</v-icon>
          <v-tooltip location="top" activator="parent">Delete</v-tooltip>
        </v-btn>
      </v-row>
    </template>
  </v-list-item>
</template>

<style src="@/src/components/styles/utils.css"></style>

<style scoped>
.empty-state {
  text-align: center;
}

.color-dot {
  width: 24px;
  height: 24px;
  background: yellow;
  border-radius: 16px;
}

.tool-icon {
  margin-inline-end: 12px;
}
</style>
