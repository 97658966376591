<script lang="ts">
import {defineComponent} from 'vue';
import GirderBrowser from './GirderBrowser.vue';

export default defineComponent({
  name: 'CasesBrowser',
  components: {
    GirderBrowser,
  },
  setup() {

    return {
    };
  },
});
</script>

<template>
  <div id="data-module" class="mx-1 fill-height">
    <div id="data-panels">
      <girder-browser />
    </div>
  </div>
</template>

<style scoped>
#data-module {
  display: flex;
  flex-flow: column;
}

#data-panels {
  flex: 2;
  overflow-y: auto;
}

.collection-header-icon {
  flex: 0;
  margin-right: 16px;
}

.patient-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
  /* 24px accomodates the open/close icon indicator */
  max-width: calc(100% - 24px);
}

.patient-header-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.empty-state {
  display: none;
}

.no-panels:empty + .empty-state {
  display: block;
}
</style>