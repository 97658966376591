<template>
  <div>
    <v-row no-gutters justify="space-between" align="center" class="mb-1">
      <v-col cols="6">
        <v-checkbox class="ml-3" label="All visible" v-model="allVisible" density="compact" hide-details
          @change="toggleAllVisibility" />
      </v-col>
      <v-col cols="6" align-self="center" class="d-flex justify-end">
        <v-btn icon variant="text" @click="previousModel" class="mr-2">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon variant="text" @click="nextModel">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Surface Models Section -->
    <v-virtual-scroll :items="surfaceModels" max-height="600" item-height="60">
      <template v-slot:default="{ item: model }">
        <v-list-item :key="model.id" :value="model.id">
          <v-row align="center">
            <v-col cols="6">
              <v-list-item-title>{{ model.metadata.name }}</v-list-item-title>
              <v-list-item-subtitle>
                Vertices: {{ model.metadata.vertices }} | Faces: {{ model.metadata.faces }}
              </v-list-item-subtitle>
            </v-col>

            <v-col cols="6" class="d-flex justify-end align-center">
              <v-list-item-action start @click="toggleVisibility(model.id)">
                <v-icon>{{ model.metadata.visible ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
              </v-list-item-action>

              <v-menu top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" color="grey-lighten-1" icon="mdi-circle" variant="text">
                    <v-icon :color="getColorString(model.metadata.displayColor)"></v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="model.metadata.displayColor" @update:modelValue="updateColor(model.id)"
                      mode="rgba" hide-inputs></v-color-picker>
                  </v-card-text>
                </v-card>
              </v-menu>

              <!-- Sliders button with scrollable popup using v-menu -->
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" icon variant="text">
                    <v-icon>mdi-tune</v-icon>
                  </v-btn>
                </template>
                <v-card :style="{ width: '400px' }">

                  <v-card-text>
                    <!-- Sliders with scrollable container -->
                    <div style="max-height: 300px; overflow-y: auto; overflow-x: hidden;">
                      <v-row v-for="(value, index) in model.metadata.modeArray" :key="index" dense>
                        <v-col cols="12">
                          <v-slider
                            class="mr-10"
                            v-model="model.metadata.modeArray[index]"
                            :min="-3.0"
                            :max="3.0"
                            :step="0.1"
                            :label="'Mode ' + index"
                            hide-details
                            @update:model-value="onSliderChange(index, $event, value, model.id)"
                          />
                        </v-col>
                      </v-row>
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>

                  <!-- Reset Button inside v-menu aligned to the right -->
                  <v-card-actions>
                    <v-spacer></v-spacer> <!-- This spacer will push the reset button to the right -->
                    <v-btn icon variant="text" @click="resetSliders(model.id)">
                      <v-icon>mdi-restore</v-icon>
                      Reset
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-menu>
              <v-btn icon="mdi-delete" variant="text" @click="deleteModel(model.id)">
                <v-icon>mdi-delete</v-icon>
                <v-tooltip location="top" activator="parent">Delete</v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue';
import { useModelStore } from '../store/datasets-models';

export default defineComponent({
  name: 'StatisticalModelModule',
  setup() {
    const modelStore = useModelStore();
    const previousValues = ref<{ [key: string]: number[] }>({}); // Store previous slider values

    const surfaceModels = computed(() => {
      return modelStore.idList
        .filter(id => modelStore.metadata[id].model) // Filter for statistical models
        .map(id => ({
          id,
          metadata: modelStore.metadata[id],
        }));
    });

    const allVisible = computed(() => {
      return surfaceModels.value.every(model => model.metadata.visible);
    });

    const selected = ref<string[]>([]);
    const selectedSome = computed(() => selected.value.length > 0);
    const sliderChanged = ref(false);  // New variable to track slider changes

    const isSelected = (id: string) => {
      return selected.value.includes(id);
    };

    const toggleSelection = (id: string) => {
      if (isSelected(id)) {
        const index = selected.value.indexOf(id);
        selected.value.splice(index, 1);
      } else {
        selected.value.push(id);
      }
    };

    function toggleAllVisibility() {
      let visible = true;
      surfaceModels.value.forEach(model => {
        if (!model.metadata.visible) {
          visible = false;
        }
      });

      surfaceModels.value.forEach(model => {
        model.metadata.visible = !visible;
      });
    }

    function toggleVisibility(id: string) {
      const isVisible = modelStore.metadata[id].visible;
      modelStore.metadata[id].visible = !isVisible;
    }

    function updateVisibility() {
      modelStore.idList.forEach(id => {
        modelStore.metadata[id].visible = (id === modelStore.currentId);
      });
    }

    function nextModel() {
      modelStore.incrementIndex();  // Increment the index
      updateVisibility();
    }

    function previousModel() {
      modelStore.decrementIndex();  // Decrement the index
      updateVisibility();
    }

    function updateColor(id: string) {
      modelStore.metaIdChanged = !modelStore.metaIdChanged;
    }

    function deleteModel(id: string) {
      modelStore.deleteData(id);  // Call the store method to delete the model
    }

    // Function to handle slider change
    function onSliderChange(idx: number, newValue: number, oldValue: number, id: string) {

      // Calculate the difference (delta)
      const delta = newValue - oldValue;

      // If delta is 0, exit early
      if (delta === 0.0) {
        return;
      }

      const polydata = modelStore.dataIndex[id];

      if (!polydata) {
        console.error('Polydata not found for model', id);
        return;
      }

      const points = polydata.getPoints(); // Fetch points (vertices)
      const modeArray = polydata.getPointData().getArrayByIndex(idx); // Fetch the mode array at index

      if (!points || !modeArray) {
        console.error('Points or Mode array not found for model', id);
        return;
      }

      const numPoints = points.getNumberOfPoints(); // Get the total number of points

      // Loop over all vertices and update them
      for (let i = 0; i < numPoints; i++) {
        const vertex = points.getPoint(i); // Fetch the vertex
        const modeValue = modeArray.getTuple(i); // Fetch the mode tuple for this vertex

        // Update the vertex by adding delta * modeValue
        const a = [
          vertex[0] + delta * modeValue[0], // X-coordinate update
          vertex[1] + delta * modeValue[1], // Y-coordinate update
          vertex[2] + delta * modeValue[2], // Z-coordinate update
        ];

        points.setPoint(i, a[0], a[1], a[2]); // Set the updated vertex back to the points

      }

      // Update the points in polydata
      // polydata.getPoints().modified(); // Ensure the points array is marked as modified

      polydata.modified();

      modelStore.metaIdChanged = !modelStore.metaIdChanged;
    }

    // Function to reset all sliders to zero and trigger the slider update function
    function resetSliders(modelId: string) {
      const modelData = modelStore.metadata[modelId];

      if (modelData && modelData.modeArray) {
        modelData.modeArray.forEach((value, index) => {
          // Reset each slider to 0, which will trigger the @update:model-value
          const oldValue = modelData.modeArray[index];
          modelData.modeArray[index] = 0;
          onSliderChange(index, 0, oldValue, modelId);
        });
      }
    }

    function getColorString(color: { r: number; g: number; b: number; a: number; }) {
      return `rgb(${color.r}, ${color.g}, ${color.b})`;
    }

    return {
      nextModel,
      previousModel,
      allVisible,
      surfaceModels,
      getColorString,
      toggleVisibility,
      toggleAllVisibility,
      deleteModel,
      resetSliders,
      selectedSome,
      isSelected,
      toggleSelection,
      updateColor,
      onSliderChange,  // Expose onSliderChange function
    };
  },
});
</script>

<style scoped>
.datasetPanel:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.panelContent {
  position: relative;
}

.slider-container {
  max-height: 200px;
  overflow-y: auto;
}

.slider-scroll {
  text-align: center;
}

.slider-scroll-text {
  font-size: 12px;
  color: gray;
}
</style>
