import { defineStore } from 'pinia';
import { useLineStore } from './lines';
import { useLandmarkStore } from './landmarks';
import { useAngleStore } from './angles';
import { useSplineStore } from './splines';
import { useSurface3DAnnotationStore } from './surfaceDrawings';
import { usePickerStore } from '../pickerStore';

export const useSurfaceMeasurementStore = defineStore('surfaceMeasurement', {
  state: () => ({
    refreshFlag: false,
  }),
  actions: {
    deleteAllLines() {
      const lineStore = useLineStore();
      lineStore.removeAllLines();
    },
    deleteAllLandmarks() {
      const landmarkStore = useLandmarkStore();
      landmarkStore.removeAllLandmarks();
    },
    deleteAllAngles() {
      const angleStore = useAngleStore();
      angleStore.removeAllAngles();
    },
    deleteAllSplines() {
      const splineStore = useSplineStore();
      splineStore.removeAllSplines();
    },
    deleteAllAnnotations() {
      const annotationStore = useSurface3DAnnotationStore();
      annotationStore.removeAllAnnotations();
    },
    deleteAllMeasurements() {
      this.deleteAllLines();
      this.deleteAllLandmarks();
      this.deleteAllAngles();
      this.deleteAllSplines();
      this.deleteAllAnnotations();
      this.refresh();
    },
    serializeMeasurements() {
      const lineStore = useLineStore();
      const landmarkStore = useLandmarkStore();
      const angleStore = useAngleStore();
      const splineStore = useSplineStore();
    
      // Serialize individual stores
      const serializedLines = lineStore.serialize();
      const serializedAngles = angleStore.serialize();
      const serializedSplines = splineStore.serialize();
      const serializedLandmarks = landmarkStore.serialize();
    
      // Combine all serialized measurements data into a single array
      const combinedMeasurements = [
        ...serializedLines,
        ...serializedAngles,
        ...serializedSplines,
        ...serializedLandmarks,
      ];
    
      // Return an object containing both serialized landmarks and measurements
      return {
        measurements: JSON.stringify(combinedMeasurements),
      };
    },
    refresh() {
      const renderer = usePickerStore().renderer;
      const lineStore = useLineStore();
      lineStore.display(renderer);
      const landmarkStore = useLandmarkStore();
      landmarkStore.display(renderer);
      const angleStore = useAngleStore();
      angleStore.display(renderer);
      const splineStore = useSplineStore();
      splineStore.display(renderer);
    },
  },
});