<script lang="ts">
import { defineComponent, toRefs, computed } from 'vue';
import { useViewStore } from '@/src/store/views';
import Landmark3D from './landmark/Landmark3D.vue';
import Paint3D from './paint/PaintWidget3D.vue'

export default defineComponent({
  props: {
    viewId: {
      type: String,
      required: true,
    },
  },
  components: {
    Landmark3D,
    Paint3D,
  },
  setup(props) {
    const { viewId: viewID } = toRefs(props);

    const active = true

    const viewType = computed(() => {
      const viewStore = useViewStore();
      return viewStore.viewSpecs[viewID.value].viewType;
    });

    return {
      active,
      viewType,
    };
  },
});
</script>

<template>
  <svg class="overlay-no-events">
    <landmark-3D v-if="active && viewType === '3D'" :view-id="viewId" />
  </svg>
  <svg class="overlay-no-events">
    <paint-3D v-if="active && viewType === '3D'" :view-id="viewId" />
  </svg>

  
</template>

<style scoped src="@/src/components/styles/vtk-view.css"></style>