import { defineStore } from 'pinia';
import vtkOpenGLHardwareSelector from '@kitware/vtk.js/Rendering/OpenGL/HardwareSelector';
import { ref, computed } from 'vue';
import { useViewStore } from '@/src/store/views';
import { FieldAssociations } from '@kitware/vtk.js/Common/DataModel/DataSet/Constants';

export const usePickerStore = defineStore('pickerStore', () => {
  const isDrawing = ref(false);
  const selector = ref(null);

  const viewStore = useViewStore();
  const viewProxy = ref(null);
  const renderer = ref(null);
  const openGLRenderWindow = ref(null);

  const setupPicker = (viewID) => {
    // Fetch viewProxy, renderer, and openGLRenderWindow
    viewProxy.value = viewStore.getViewProxy(viewID);
    renderer.value = viewProxy.value?.getRenderer();
    openGLRenderWindow.value = viewProxy.value?.getInteractor().getView();

    // Initialize the selector if not already initialized
    if (!selector.value) {
      selector.value = vtkOpenGLHardwareSelector.newInstance({
        captureZValues: true,
      });
      selector.value.setFieldAssociation(FieldAssociations.FIELD_ASSOCIATION_CELLS);
      selector.value.attach(openGLRenderWindow.value, renderer.value);
    }
  };

  return {
    isDrawing,
    selector,
    viewProxy: computed(() => viewProxy.value),
    renderer: computed(() => renderer.value),
    openGLRenderWindow: computed(() => openGLRenderWindow.value),
    setupPicker,
  };
});