<template>
    <div>
      <v-card-title>
        <span class="headline">Create New Cohort</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            label="Cohort Name"
            v-model="newCohortName"
            required
          ></v-text-field>
          <v-select
            label="Anatomy"
            :items="anatomies"
            v-model="selectedAnatomy"
            item-title="text"
            item-value="value"
            required
          ></v-select>
          <v-switch
            label="Virtual Cohort"
            v-model="isVirtual"
          ></v-switch>
          <v-slider
            v-if="isVirtual"
            label="Number of Samples"
            v-model="sampleCount"
            :step="1"
            :min="1"
            :max="100"
            required
          >
            <template v-slot:append>
              <v-text-field
                v-model="sampleCount"
                type="number"
                class="mt-0"
                style="width: 100px;"
              ></v-text-field>
            </template>
          </v-slider>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="createCohort">
          <v-icon left>mdi-plus-box</v-icon>
          Create
        </v-btn>
        <v-btn color="secondary" @click="$emit('close')">
          <v-icon left>mdi-cancel</v-icon>
          Cancel
        </v-btn>
      </v-card-actions>
    </div>
  </template>
  
  <script lang="ts">
  import { ref, computed } from 'vue';
  import { useGirderStore } from '../store/girder-store';
  import { useMessageStore } from '../store/messages';
  
  export default {
    name: 'CohortCreator',
    emits: ['close', 'created'],
    setup(_, { emit }) {
      const girder = useGirderStore();
      const messageStore = useMessageStore();
  
      const newCohortName = ref('');
      const selectedAnatomy = ref('');
      const isVirtual = ref(false);
      const sampleCount = ref(0);
  
      const anatomies = computed(() => girder.anatomies);
  
      const createCohort = async () => {
        if (!newCohortName.value || !selectedAnatomy.value || (isVirtual.value && sampleCount.value <= 0)) {
          messageStore.addSuccess('Please fill all required fields.');
          return;
        }
  
        try {
          const cohortData = await girder.createCohort({
            name: newCohortName.value,
            anatomy: selectedAnatomy.value,
            isVirtual: isVirtual.value,
            samples: isVirtual.value ? sampleCount.value : undefined,
          });

          if(cohortData.success){
            girder.setLoadedCase(cohortData.data);
            girder.listCohorts();
            messageStore.addSuccess('Cohort created successfully!');

            await girder.submitCohortJob(cohortData.data);
            messageStore.addSuccess('Cohort job submitted successfully!');

            emit('created');
            emit('close');
          } else {
            if(cohortData.message){
                messageStore.addError(cohortData.message);
            }
          }
        } catch (error) {
        //   console.error('Failed to create cohort:', error);
          messageStore.addError('Failed to create cohort. Please try again.');
        }
      };
  
      return {
        newCohortName,
        selectedAnatomy,
        isVirtual,
        sampleCount,
        anatomies,
        createCohort,
      };
    },
  };
  </script>
  
  <style scoped>
  /* Add styles as necessary */
  </style>