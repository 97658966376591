<script lang="ts">
import { defineComponent, onMounted, onBeforeUnmount, toRefs, computed } from 'vue';
import { usePickerStore } from '@/src/store/pickerStore';
import { useSurface3DAnnotationStore } from '@/src/store/tools/surfaceDrawings';
import { useModelStore } from '@/src/store/datasets-models';
import { throttle } from '@kitware/vtk.js/macros';
import vtkSphereSource from '@kitware/vtk.js/Filters/Sources/SphereSource';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import { manageVTKSubscription } from '@/src/composables/manageVTKSubscription';

export default defineComponent({
  props: {
    viewId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { viewId: viewID } = toRefs(props);
    const pickerStore = usePickerStore();
    const annotationStore = useSurface3DAnnotationStore();
    const modelStore = useModelStore();

    // Setup picker from PickerStore, which manages selector, renderer, etc.
    pickerStore.setupPicker(viewID.value);

    // Sphere actor for indicating selection
    const sphereSource = vtkSphereSource.newInstance();
    sphereSource.setRadius(3.0);
    const sphereMapper = vtkMapper.newInstance();
    const sphereActor = vtkActor.newInstance();
    sphereActor.setPickable(false);
    sphereMapper.setInputConnection(sphereSource.getOutputPort());
    sphereActor.setMapper(sphereMapper);

    function getCellNeighbors(cellId, input) {
      const neighbors = [];
      if (!input.getCells()) {
        input.buildCells();
        input.buildLinks();
      }

      const cellPoints = input.getCellPoints(cellId).cellPointIds;
      const numPoints = cellPoints.length;

      for (let i = 0; i < numPoints; i++) {
        const point1 = cellPoints[i];
        const point2 = cellPoints[(i + 1) % numPoints];
        const edgeNeighbors = input.getCellEdgeNeighbors(cellId, point1, point2);

        for (let j = 0; j < edgeNeighbors.length; j++) {
          neighbors.push(edgeNeighbors[j]);
        }
      }

      return neighbors;
    }

    function extendNeighbors(cellId, input, iterations) {
      const visitedCells = new Set();

      function recursiveExtend(cellId, remainingIterations) {
        if (remainingIterations <= 0) {
          return;
        }

        const neighbors = getCellNeighbors(cellId, input);
        neighbors.forEach((neighborId) => {
          if (!visitedCells.has(neighborId)) {
            visitedCells.add(neighborId);
            recursiveExtend(neighborId, remainingIterations - 1);
          }
        });
      }

      visitedCells.add(cellId);
      recursiveExtend(cellId, iterations);

      return Array.from(visitedCells);
    }

    function handleMouseMove(callData) {
      if (!pickerStore.isDrawing || !pickerStore.selector) {
        return;
      }

      const pos = callData.position;
      const x = pos.x;
      const y = pos.y;

      pickerStore.selector
        .selectAsync(pickerStore.renderer, x, y, x, y)
        .then((selection) => {
          if (selection.length > 0) {
            const {
              worldPosition: rayHitWorldPosition,
              attributeID,
              prop,
            } = selection[0].getProperties();

            const input = prop.getMapper().getInputData();
            const scalars = input.getCellData().getScalars();

            if (scalars) {
              // Set scalar value for the cell and its neighbors
              scalars.setTuple(attributeID, annotationStore.getBrushValue());

              const neighbors = extendNeighbors(attributeID, input, annotationStore.brushSize);
              neighbors.forEach((neighborId) => {
                scalars.setTuple(neighborId, annotationStore.getBrushValue());
              });

              scalars.modified();
              input.modified();

              sphereSource.setCenter(rayHitWorldPosition);
              pickerStore.viewProxy?.render();
            } else {
              const idx = modelStore.findIndexByPolyData(input);
              if (idx) {
                const annotation = annotationStore.addAnnotation('Scalars', idx);
                annotationStore.setVisibleAnnotation(annotation.modelId, annotation.id);
              }
            }
          }
        });
    }

    function handleKeyDown(event) {
      if (event.key === 'q') {
        pickerStore.isDrawing = true;
        pickerStore.renderer?.addActor(sphereActor);
      }
    }

    function handleKeyUp(event) {
      if (event.key === 'q') {
        pickerStore.isDrawing = false;
        pickerStore.renderer?.removeActor(sphereActor);
        pickerStore.viewProxy?.renderLater();
      }
    }

    const mouseMoveSubscription = pickerStore.viewProxy?.getInteractor().onMouseMove(throttle(handleMouseMove, 20));

    onMounted(() => {
      if (mouseMoveSubscription) {
        manageVTKSubscription(mouseMoveSubscription);
      }

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      if (mouseMoveSubscription) {
        mouseMoveSubscription.unsubscribe();
      }
    });

    return {};
  },
});
</script>

<template>
  <div>
    <canvas ref="canvas" />
  </div>
</template>