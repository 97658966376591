<template>
  <div class="vtk-container-wrapper">
    <!-- Info icon with tooltip -->
    <div class="top-right-info">
      <v-menu open-on-hover location="bottom left" dark max-width="350px">
        <template v-slot:activator="{ props }">
          <v-icon
            v-bind="props"
            dark
            size="x-large"
            class="pointer-events-all hover-info"
          >
            mdi-information
          </v-icon>
        </template>
        <v-list class="bg-grey-darken-3">
          <v-list-item>
            <v-list-item-title class="font-weight-bold">
              {{ modelMetadata?.name }}
            </v-list-item-title>

            <v-divider />

            <!-- Displaying individual measurements -->
            <v-list-item v-for="(measurement, index) in measurements" :key="index">
              <v-list-item-title class="font-weight-bold">
                {{ measurement.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ measurement.type }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ measurement.value }}</v-list-item-subtitle>
            </v-list-item>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- VTK Container -->
    <div class="vtk-container" ref="vtkContainerRef"></div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, onBeforeUnmount, defineComponent, PropType, watch, computed } from 'vue';
import vtkRenderer from '@kitware/vtk.js/Rendering/Core/Renderer';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkRenderWindowInteractor from '@kitware/vtk.js/Rendering/Core/RenderWindowInteractor';
import { useModelStore } from '@/src/store/datasets-models';
import { storeToRefs } from 'pinia';
import { useSharedCameraStore } from '@/src/store/shared-camera';
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow';
import vtkInteractorStyleTrackballCamera from '@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera';
import { useGroupStore } from '../store/datasets-groups';

export default defineComponent({
  name: 'ModelRenderer',
  props: {
    modelId: {
      type: String as PropType<string>,
      required: true,
    },
    mainRenderWindow: {
      type: Object,
      required: true,
    },
    mainRenderWindowView: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const vtkContainerRef = ref<HTMLElement | null>(null);
    const renderer = vtkRenderer.newInstance();
    const sharedCameraStore = useSharedCameraStore();
    const modelStore = useModelStore();
    const groupStore = useGroupStore();

    const interactor = vtkRenderWindowInteractor.newInstance();
    const mapper = vtkMapper.newInstance();
    const actor = vtkActor.newInstance();
    const renderWindow = vtkRenderWindow.newInstance();
    const { sharedCamera, updated } = storeToRefs(sharedCameraStore);

    // Get metadata of the current model
    const modelMetadata = computed(() => modelStore.metadata[props.modelId]);

    // Use group store to extract measurements for the group associated with the modelId
    const measurements = computed(() => {
      return groupStore.getMeasurementsForGroup(props.modelId);
    });

    watch(
      [sharedCamera, updated],
      ([camera]) => {
        if (renderer && renderWindow) {
          renderer.setActiveCamera(camera);
          renderWindow.render();
        }
      },
      { immediate: true }
    );

    const syncCamera = () => {
      if (renderer && renderWindow) {
        const activeCamera = renderer.getActiveCameraAndResetIfCreated();
        sharedCameraStore.updateSharedCamera(activeCamera);
      }
    };

    onMounted(() => {
      if (vtkContainerRef.value) {
        vtkContainerRef.value.id = `renderer-${props.modelId}`;

        const polyData = modelStore.dataIndex[props.modelId];
        if (!polyData) {
          console.error(`Model with id ${props.modelId} not found`);
          return;
        }

        props.mainRenderWindow.addRenderWindow(renderWindow);
        const renderWindowView = props.mainRenderWindowView.addMissingNode(renderWindow);
        renderWindow.addView(renderWindowView);
        renderWindowView.setContainer(vtkContainerRef.value);

        interactor.setView(renderWindowView);
        interactor.initialize();
        interactor.setInteractorStyle(
          vtkInteractorStyleTrackballCamera.newInstance()
        );
        interactor.setContainer(vtkContainerRef.value);
        interactor.onMouseMove(syncCamera);
        interactor.onMouseWheel(syncCamera);

        mapper.setInputData(polyData);
        mapper.setScalarVisibility(false);

        actor.setMapper(mapper);
        renderer.addActor(actor);
        renderer.setBackground(0.6, 0.6, 0.6);
        renderer.resetCamera();

        const groupActors = groupStore.getActorsForGroup(props.modelId);
        groupActors.forEach((groupActor) => {
          renderer.addActor(groupActor);
        });

        renderWindow.addRenderer(renderer);
        renderWindow.render();
      }
    });

    onBeforeUnmount(() => {
      renderer.delete();
      renderWindow.delete();
      interactor.delete();
      mapper.delete();
      actor.delete();
    });

    return {
      vtkContainerRef,
      modelMetadata,
      measurements,
    };
  },
});
</script>

<style scoped>
.vtk-container-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.vtk-container {
  width: 100%;
  height: 100%;
}

.top-right-info {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.hover-info {
  cursor: pointer;
}
</style>
