import { defineStore } from 'pinia';
import { ref } from 'vue';
import vtkCamera from '@kitware/vtk.js/Rendering/Core/Camera';

export const useSharedCameraStore = defineStore('sharedCamera', {
  // State
  state: () => ({
    sharedCamera: ref(vtkCamera.newInstance()),
    updated: ref(0),
    initialized: ref(false),
  }),

  // Getters
  getters: {
    getSharedCamera(): vtkCamera {
      return this.sharedCamera;
    },
  },

  // Actions
  actions: {
    updateSharedCamera(camera: vtkCamera) {
      this.sharedCamera = camera;
      this.updated += 1;
      if(!this.initialized) {
        this.initialized = true;
      }
    },
  },
});

export default useSharedCameraStore;