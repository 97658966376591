<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useLineStore } from '@/src/store/tools/lines';

export default defineComponent({
  setup() {
    const lineStore = useLineStore();
    const editingId = ref<number | null>(null);
    const newName = ref('');

    const lines = computed(() => {
      return lineStore.lines;
    });

    function remove(id: number) {
      lineStore.removeLine(id);
    }

    function hover(id: number | null) {
      lineStore.updateHoveredLine(id); // Update hover state in lineStore
    }

    function startEditing(id: number, currentName: string) {
      editingId.value = id;
      newName.value = currentName;
    }

    function saveName(id: number) {
      if (newName.value.trim()) {
        lineStore.updateLineName(id, newName.value);
      }
      editingId.value = null;
    }

    function cancelEditing() {
      editingId.value = null;
    }

    return {
      lines,
      hover,
      remove,
      editingId,
      newName,
      startEditing,
      saveName,
      cancelEditing,
    };
  },
});
</script>

<template>
  <v-list-item
    v-for="line in lines"
    :key="line.id"
    lines="two"
    @mouseenter="hover(line.id)"
    @mouseleave="hover(null)"
  >
    <template #prepend>
      <v-icon class="tool-icon">mdi-vector-line</v-icon>
    </template>
    <v-list-item-title v-bind="$attrs">
      <template v-if="editingId === line.id">
        <v-text-field v-model="newName" dense @blur="saveName(line.id)" @keydown.enter="saveName(line.id)" @keydown.esc="cancelEditing" />
      </template>
      <template v-else>
        <span @click="startEditing(line.id, line.name)">{{ line.name || 'Unnamed (Double-click)' }}</span>
      </template>
    </v-list-item-title>
    <v-list-item-subtitle>
      <v-row>
        <v-col>Distance: {{ line.distance }}</v-col>
      </v-row>
    </v-list-item-subtitle>
    <template #append>
      <v-row>
        <v-chip v-if="!line.name" label small color="success" text-color="white">New</v-chip>
        <v-btn icon="mdi-delete" variant="text" @click="remove(line.id)">
          <v-icon>mdi-delete</v-icon>
          <v-tooltip location="top" activator="parent">Delete</v-tooltip>
        </v-btn>
      </v-row>
    </template>
  </v-list-item>
</template>

<style src="@/src/components/styles/utils.css"></style>

<style scoped>
.empty-state {
  text-align: center;
}

.color-dot {
  width: 24px;
  height: 24px;
  background: yellow;
  border-radius: 16px;
}

.tool-icon {
  margin-inline-end: 12px;
}
</style>