<template>
    <v-dialog v-model="visible" scrollable max-width="60%">
      <v-card>
        <v-card-title>
          <span class="headline">Cohort Browser</span>
        </v-card-title>
  
        <v-card-text>
          <!-- Filters -->
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Filter by Name"
                v-model="nameFilter"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                label="Filter by Anatomy"
                :items="anatomyOptions"
                v-model="anatomyFilter"
                item-title="text"
                item-value="value"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                label="Filter by Type"
                :items="typeOptions"
                v-model="typeFilter"
                clearable
              ></v-select>
            </v-col>
          </v-row>
  
          <!-- Cohort Listing Table -->
          <v-data-table
            :headers="headers"
            :items="filteredCohortList"
            hide-default-footer
          >
            <template v-slot:item.prepared="{ item }">
              <v-icon :color="item.prepared ? 'green' : 'orange'">
                {{ item.prepared ? 'mdi-check-circle' : 'mdi-timer-sand' }}
              </v-icon>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn color="primary" small @click="loadCohort(item.id)">
                Load
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script lang="ts">
  import { defineComponent, ref, computed, onMounted } from 'vue';
  import { useGirderStore } from '../store/girder-store';
  import { useSurfaceMeasurementStore } from '../store/tools/surfaceMeasurements';
  import { useDatasetStore } from '../store/datasets';
  import { progressCallback, uploadProgress, resetProgress } from '../store/progress-store';
  
  export default defineComponent({
    name: 'CohortBrowser',
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['close', 'cohort-loaded'],
    setup(props, { emit }) {
      const girder = useGirderStore();
      const surfaceStore = useSurfaceMeasurementStore();
      const datasetStore = useDatasetStore();
  
      const nameFilter = ref('');
      const anatomyFilter = ref('');
      const typeFilter = ref('');
  
      const headers = ref([
        { title: 'Name', value: 'name', sortable: true },
        { title: 'Anatomy', value: 'anatomy', sortable: true },
        { title: 'Type', value: 'realVsVirtual', sortable: true },
        { title: 'Prepared', value: 'prepared', sortable: false },
        { title: 'Action', value: 'action', sortable: false },
      ]);
  
      const anatomyOptions = computed(() => girder.anatomies);
      const typeOptions = ref(['Real', 'Virtual']);
  
      const filteredCohortList = computed(() => {
        const cohorts = Object.values(girder.cohorts);
  
        return cohorts.filter((cohort) => {
          const matchesName = nameFilter.value
            ? cohort.name.toLowerCase().includes(nameFilter.value.toLowerCase())
            : true;
  
          const matchesAnatomy = anatomyFilter.value
            ? cohort.anatomy === anatomyFilter.value
            : true;
  
          const matchesType = typeFilter.value
            ? cohort.realVsVirtual === typeFilter.value
            : true;
  
          return matchesName && matchesAnatomy && matchesType;
        });
      });
  
      const fetchCohorts = async () => {
        try {
          await girder.listCohorts();
        } catch (error) {
          console.error('Failed to fetch cohorts:', error);
        }
      };
  
      const loadCohort = async (id: string) => {
        try {
          resetProgress(); // Reset progress before starting
          surfaceStore.deleteAllMeasurements();
          datasetStore.deleteAllData();
          girder.resetPaths();
  
          // Call loadCohort with progress tracking
          await girder.loadCohort(id, progressCallback);
  
          // Notify parent component that the cohort has been loaded
          emit('cohort-loaded', girder.loadedCase);
  
          progressCallback(100);
        } catch (error) {
          console.error('Error loading cohort:', error);
        } finally {
          resetProgress(); // Reset progress once complete
        }
      };
  
      const closeDialog = () => {
        emit('close');
      };
  
      onMounted(fetchCohorts);
  
      return {
        nameFilter,
        anatomyFilter,
        typeFilter,
        anatomyOptions,
        typeOptions,
        headers,
        filteredCohortList,
        loadCohort,
        closeDialog,
      };
    },
  });
  </script>  